import React from "react"
import ReactModal from "react-modal"
import styled, { createGlobalStyle, css } from "styled-components"
import { CloseIcon } from "../icons/icons"
import { breakpoints, ThemeProvider } from "../styles"
import { MAX_WIDTH } from "../styles/breakpoints"
import { SPACE_2XL, SPACE_XL, COLOR_GRAY_900_RGBA } from "../styles/primitives"
import { createScrollbarStyles } from "./scrollHelpers"
import { Text } from "./Text"

const ReactModalGlobalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${COLOR_GRAY_900_RGBA};
    z-index: 100;
  }

  .ReactModal__Body--open{
    overflow:hidden;
  }
`

const StyledReactModal = styled(ReactModal)`
  width: 100%;
  max-width: ${MAX_WIDTH - 84}px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 24px 24px 0 0;
  background-color: ${({ theme }) => theme.backgroundColor};
  outline: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${breakpoints({
    SM: css`
      top: ${54}px;
      padding: 60px 30px 0px;
    `,
    MD: css`
      margin-top: ${SPACE_2XL}px;
      padding: 80px 40px 0px;
    `,
    LG: css`
      margin-top: ${SPACE_2XL}px;
      padding: 80px 60px 0px;
    `,
    XL: css`
      margin-top: 118px;
      padding: 80px 50px 0px;
    `,
    MAX: css`
      padding: 120px 100px 0px;
    `,
  })};
`

const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${SPACE_XL / 2}px;
  right: ${SPACE_XL / 2}px;

  ${breakpoints({
    SM: css`
      width: ${SPACE_XL}px;
      height: ${SPACE_XL}px;
    `,
  })}
`

// if you want to provide headline which scrolls with content
export const Modal2Headline = styled(Text).attrs({ type: "primary" })`
  margin-bottom: ${SPACE_XL}px;
`

const ChildrenContainer = styled.div`
  ${createScrollbarStyles()}
  overflow-y: auto;
  height: 100%;
  padding-bottom: 60px;
  padding-right: 40px;
  width: calc(100% + 40px);

  ${breakpoints({
    SM: css`
      padding-bottom: 60px;
    `,
    MD: css`
      padding-bottom: 60px;
    `,
    LG: css`
      padding-bottom: 80px;
    `,
    XL: css`
      padding-bottom: 80px;
    `,
    MAX: css`
      padding-bottom: 100px;
    `,
  })};
`

export function Modal2({ children, onClose, overlayClassName, ...otherProps }) {
  return (
    <ThemeProvider themeName="stone">
      <ReactModalGlobalStyles />
      <StyledReactModal
        isOpen={true}
        overlayClassName={`ReactModal__Overlay ${overlayClassName || ""}`}
        onRequestClose={onClose}
      >
        <CloseIconContainer onClick={onClose}>
          <CloseIcon />
        </CloseIconContainer>
        <ChildrenContainer>{children}</ChildrenContainer>
      </StyledReactModal>
    </ThemeProvider>
  )
}
