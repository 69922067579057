import { getURLHashForEntry } from "../anchorLinks"

export function resolveLink(slugPrefix, entry) {
  const [, country] = slugPrefix.split("-")
  const entryURLHash = getURLHashForEntry(entry)

  const countrySpecificPage = getSpecificCMSPageForCountryScope(entry, country)
  if (countrySpecificPage) {
    return resolveLink(slugPrefix, countrySpecificPage)
  }

  if (entry.cms_page?.length > 0) {
    return resolveLink(slugPrefix, entry.cms_page[0])
  }

  if (entryURLHash) {
    return `#${entryURLHash}`
  }

  const entryURLPart = getPathForEntry(entry)

  return `/${slugPrefix}/${entryURLPart}/`
}

function getPathForEntry(entry) {
  const slug = entry?.slug?.replace?.(/^\//, "")

  if (slug) {
    return slug
  }

  if (entry.__typename === "ContentfulAsset") {
    return slug
  }

  return ""
}

function getSpecificCMSPageForCountryScope(page, country) {
  return (
    page.countrySpecificPages?.find(countrySpecificPage =>
      countrySpecificPage.countryScope?.includes(country)
    ) || null
  )
}
