export function useSubmitButtonState(formState) {
  return !formState.isValid
    ? "INVALID"
    : formState.isSubmitting
    ? "SUBMITTING"
    : formState.isSubmitSuccessful &&
      Object.keys(formState.touchedFields).length === 0
    ? "SUCCESS"
    : "INITIAL"
}
