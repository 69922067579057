import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import ReactYouTube from "react-youtube"
import styled from "styled-components"
import { useDimensions } from "../../useDimensions"

export const YouTubeVideo = forwardRef(
  ({ videoEntry, autoPlay, onLoad, ...otherProps }, ref) => {
    const youtubeVideoId = useMemo(() => {
      const url = new URL(videoEntry.youtubeUrl)
      return new URLSearchParams(url.search).get("v")
    }, [videoEntry.youtubeUrl])

    const videoSizeElementRef = useRef()
    const width = useDimensions(videoSizeElementRef).width

    const reactYouTubeOptions = useMemo(
      () => ({
        height: (width / ASPECT_RATIO).toString(),
        width: width.toString(),
        playerVars: {
          autoplay: autoPlay ? 1 : 0,
          mute: videoEntry.muted ? 1 : 0,
        },
      }),
      [autoPlay, videoEntry.muted, width]
    )

    const [videoRef, setVideoRef] = useState(null)
    const handleReady = useCallback(event => {
      setVideoRef(event.target), onLoad()
    }, [])

    useEffect(() => {
      if (!videoRef) {
        return
      }
    }, [autoPlay, videoRef])

    return (
      <Root {...otherProps}>
        <VideoSize ref={videoSizeElementRef}>
          <ReactYouTube
            videoId={youtubeVideoId}
            opts={reactYouTubeOptions}
            onReady={handleReady}
          />
        </VideoSize>
      </Root>
    )
  }
)

const Root = styled.div``
const VideoSize = styled.div``

const ASPECT_RATIO = 16 / 9
