import { useGlobalTranslation } from "../../globalValues"

export function ErrorMessage({ error, variant = "light", ...otherProps }) {
  const fieldIsRequiredMessage = useGlobalTranslation(
    "fieldIsRequiredValidation"
  )
  return error.message
    ? error.message
    : error.type === "required"
    ? fieldIsRequiredMessage
    : ""
}
