import { useLayoutEffect, useState } from "react"

export function useDimensions(elementRef) {
  const [dimensions, setDimensions] = useState({ width: 640, height: 480 })

  useLayoutEffect(() => {
    function update() {
      setDimensions(elementRef.current.getBoundingClientRect())
    }

    update()
    window.addEventListener("resize", update)

    return () => window.removeEventListener("resize", update)
  }, [elementRef])

  return dimensions
}
