import React from "react"
import styled from "styled-components"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"

const SVGRoot = styled.svg`
  stroke-width: 2;

  path {
    transition: stroke ${ANIMATION_DURATION}ms ${ANIMATION_EASING};
  }
`

export function ArrowRightIcon({ color, ...otherProps }) {
  return (
    <SVGRoot
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M17.1484 6L27.1484 16L17.1484 26"
        stroke={color}
        className="colored-fg-stroke"
      />
      <path d="M3 16H27.1" stroke={color} className="colored-fg-stroke" />
    </SVGRoot>
  )
}
