import React, {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { LoadingComponentDecorator } from "./LoadingComponentDecorator"

const StyledGatsbyImage = styled(GatsbyImage)``

let counter = 0

export const Image = forwardRef(({ asset, onLoad, ...otherProps }, ref) => {
  const image = useMemo(() => getImage(asset), [asset])
  const uniqueId = useMemo(() => `GatsbyImage-${counter++}`, [])
  const onLoadWasCalledRef = useRef(false)

  const finalOnLoad = useCallback(() => {
    onLoadWasCalledRef.current = true
    onLoad?.()
  }, [onLoad])

  useLayoutEffect(() => {
    let timeoutId

    function scheduleCheck() {
      timeoutId = setTimeout(checkIfLoaded, 1000)
    }

    function checkIfLoaded() {
      const imageElement = document.querySelector(`#${uniqueId}`)
      if (
        imageElement &&
        (imageElement.naturalHeight > 0 || imageElement.naturalWidth > 0)
      ) {
        if (!onLoadWasCalledRef.current) {
          finalOnLoad()
        }
      } else {
        scheduleCheck()
      }
    }

    scheduleCheck()

    return () => clearTimeout(timeoutId)
  }, [finalOnLoad, onLoad, uniqueId])

  return (
    <StyledGatsbyImage
      image={image}
      alt={asset.description}
      style={{ position: null, display: null, ...otherProps.style }}
      ref={ref}
      id={uniqueId}
      onLoad={finalOnLoad}
      {...otherProps}
    />
  )
})

export function LoadingDecoratedImage({
  avoidColors,
  colorShift,
  className,
  style,
  onLoad,
  onReveal,
  ...otherProps
}) {
  return (
    <LoadingComponentDecorator
      avoidColors={avoidColors}
      colorShift={colorShift}
      className={className}
      style={style}
      onLoad={onLoad}
      onReveal={onReveal}
    >
      {({ onLoad, onError }) => (
        <SLoadingDecoratedImage
          onLoad={onLoad}
          onError={onError}
          {...otherProps}
        />
      )}
    </LoadingComponentDecorator>
  )
}

const SLoadingDecoratedImage = styled(Image)`
  display: block;
  height: 100%;
`
