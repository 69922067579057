import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { resolveLink } from "../../utils/linkResolver"
import { linkStyles } from "./linkStyles"
import { ModalLink } from "./ModalLink"
import { useLocale } from "../../locale"

export const InternalLink = styled(GatsbyLink)`
  ${linkStyles};
`
export const ExternalLink = styled.a`
  ${linkStyles};
`

export function Link({ to, target, queryParams, ...otherProps }) {
  const { slugPrefix } = useLocale()

  if (typeof to === "string") {
    return (
      <ExternalLink
        href={prepareEmailOrPhoneLinks(to)}
        target={
          target
            ? target
            : to.startsWith("#") || !to.startsWith("http")
            ? ""
            : "_blank"
        }
        rel="noopener noreferrer"
        {...otherProps}
      />
    )
  }

  if (to.__typename === "ContentfulAsset") {
    return (
      <ExternalLink
        href={`https:${to.file.url}`}
        target={target || "_blank"}
        rel="noopener noreferrer"
        {...otherProps}
      />
    )
  }

  if (to.__typename === "ContentfulModal") {
    return <ModalLink modalEntry={to} target={target} {...otherProps} />
  }

  if (to.__typename === "ContentfulCmsPage" && target === "_blank") {
    return (
      <ExternalLink
        href={resolveLink(slugPrefix, to)}
        target={target}
        rel="noopener noreferrer"
        {...otherProps}
      />
    )
  }

  return (
    <InternalLink
      to={resolveLink(slugPrefix, to)}
      target={target}
      {...otherProps}
    />
  )
}

function prepareEmailOrPhoneLinks(link) {
  if (
    link.startsWith("http") ||
    link.startsWith("/") ||
    link.startsWith("#") ||
    link.startsWith("mailto:") ||
    link.startsWith("tel:")
  ) {
    return link
  }

  return link.match("@")
    ? `mailto:${link}`
    : `tel:${link.replace(/[+() ]/g, "")}`
}

/**
 * HiddenLink is used to trigger Gatsby preload on pages where we use navigate() instead of <Link />'s.
 * This is to prevent a page refresh and instead do a frontend redirect, useful for collecting metrics.
 */
export const HiddenLink = styled(Link)`
  display: none;
`
