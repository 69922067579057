const pageTypeNames = [
  "ContentfulCmsPage",
  "ContentfulPost",
]

export function getURLHashForEntry(entry) {
  if (pageTypeNames.includes(entry.__typename)) {
    return null
  }

  const anchorSlug = entry.slug || entry.id

  if (!anchorSlug) {
    return null
  }

  return anchorSlug
}
