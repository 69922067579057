import React from "react"
import styled, { css } from "styled-components"

import { ArrowRightIcon } from "../../icons/ArrowRightIcon"
import {
  FONT_SIZE_1XL,
  SPACE_L,
  SPACE_M,
  SPACE_TOKEN_INLINE_1XL,
  SPACE_TOKEN_INLINE_XL,
  SPACE_TOKEN_STACK_XL,
  SPACE_XL,
} from "../../styles/primitives"
import { useTheme } from "../../styles/themes"
import { breakpoints } from "../../styles/helpers"
import {
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  TEXT_TOKEN_COPY_BREAKPOINT_XL,
} from "../../styles"

const Container = styled.li`
  list-style: none;
  margin: 0;
  display: flex;
  color: ${({ theme }) => theme.textColor};

  & + & {
    ${breakpoints({
      SM: css`
        margin-top: ${6}px;
      `,
      MD: css`
        margin-top: ${SPACE_XL}px;
      `,
      LG: css`
        margin-top: ${36}px;
      `,
      XL: css`
        margin-top: ${30}px;
      `,
    })}
  }
`

const ContentWrapper = styled.div`
  list-style: none;
  margin: 0;
  flex: 1;
  align-self: center;
`

const IconWrapper = styled.div`
  margin-right: ${SPACE_L}px;
  display: flex;
  align-items: center;

  ${breakpoints({
    SM: css`
      margin-right: ${SPACE_M}px;
      height: ${TEXT_TOKEN_COPY_BREAKPOINT_SM.fontSize *
      TEXT_TOKEN_COPY_BREAKPOINT_SM.lineHeight}rem;
    `,
    MD: css`
      height: ${TEXT_TOKEN_COPY_BREAKPOINT_MD.fontSize *
      TEXT_TOKEN_COPY_BREAKPOINT_MD.lineHeight}rem;
    `,
    LG: css`
      height: ${TEXT_TOKEN_COPY_BREAKPOINT_LG.fontSize *
      TEXT_TOKEN_COPY_BREAKPOINT_LG.lineHeight}rem;
    `,
    XL: css`
      height: ${TEXT_TOKEN_COPY_BREAKPOINT_XL.fontSize *
      TEXT_TOKEN_COPY_BREAKPOINT_XL.lineHeight}rem;
    `,
  })}
`

const IndexWrapper = styled.div`
  font-size: ${FONT_SIZE_1XL}rem;
  line-height: 1em;
`

export function ListItem({ children, index, ...otherProps }) {
  const { theme } = useTheme()

  return (
    <Container {...otherProps}>
      <IconWrapper>
        {typeof index === "number" ? (
          <IndexWrapper>{index + 1}</IndexWrapper>
        ) : (
          <ArrowRightIcon color={theme.textColor} />
        )}
      </IconWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  )
}
