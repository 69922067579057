import React, { useLayoutEffect, useRef } from "react"
import { useMemo } from "react"
import styled, { css } from "styled-components"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"

export function ColorEffect({
  state,
  defaultColor,
  borderWidth,
  colors,
  ...otherProps
}) {
  const colorElements = useMemo(() => [], [])

  const lastRenderedState = useRef(null)

  useLayoutEffect(() => {
    if (lastRenderedState.current === null) {
      lastRenderedState.current = "initial"
      return
    }
    lastRenderedState.current = state

    if (state === "active") {
      colorElements.forEach(
        /**
         *
         * @param {Element} element
         * @returns
         */
        (element, index) =>
          element.animate(
            [
              { offset: 0, opacity: "1", transform: "translateX(-100%)" },
              { offset: 1, opacity: "1", transform: "translateX(0%)" },
            ],
            {
              duration: SLIDE_IN_DURATION,
              delay: index * SLIDE_IN_STAGGER_DELAY,
              fill: "forwards",
              id: "slideIn",
            }
          )
      )
    } else {
      colorElements.forEach(
        /**
         *
         * @param {Element} element
         * @returns
         */
        (element, index) => {
          const runningSlideInAnimations = element
            .getAnimations()
            .filter(({ id }) => id === "slideIn")
          if (
            runningSlideInAnimations.some(
              animation => animation.playState === "running"
            )
          ) {
            runningSlideInAnimations.forEach(animation => {
              animation.reverse()
              animation.id = "slideInReverse"
            })
            return
          }

          element.animate(
            [
              { offset: 0, opacity: "1", transform: "translateX(0%)" },
              { offset: 1, opacity: "0", transform: "translateX(0%)" },
              { offset: 1, opacity: "0", transform: "translateX(-100%)" },
            ],
            {
              duration: FADE_OUT_DURATION,
              delay: (colorElements.length - index) * FADE_OUT_DURATION * 0.25,
              fill: "forwards",
              id: "fadeOut",
            }
          )
        }
      )
    }
  }, [colorElements, state])

  return (
    <ColorEffectRoot {...otherProps} state={state}>
      <BackgroundAndBorder
        borderWidth={borderWidth}
        defaultColor={defaultColor}
      >
        {colors.map((color, index) => (
          <ColorEffectColor
            key={index}
            style={{
              backgroundColor: color,
            }}
            ref={element => {
              if (element !== null) {
                colorElements[index] = element
              }
            }}
          />
        ))}
      </BackgroundAndBorder>
    </ColorEffectRoot>
  )
}

const SLIDE_IN_DURATION = 400
const SLIDE_IN_STAGGER_DELAY = 200
const FADE_OUT_DURATION = 200

const ColorEffectColor = styled.div`
  position: absolute;
  transform: translateX(-100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const BackgroundAndBorder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transform: translateZ(0px);
  transition: background-color ${ANIMATION_DURATION}ms ${ANIMATION_EASING};

  ${({ defaultColor, borderWidth }) =>
    borderWidth
      ? css`
          border: ${borderWidth}px solid ${defaultColor};
        `
      : css`
          background-color: ${defaultColor};
        `};
`

const ColorEffectRoot = styled.div`
  overflow: hidden;
`
