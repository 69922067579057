const siteCountriesByLanguage = {
  de: ["de", "at"],
  en: ["de", "nl"],
  fr: ["fr"],
  nl: ["nl"],
}

const siteLanguagesByCountries = (() => {
  const result = {}

  for (const languageKey in siteCountriesByLanguage) {
    for (const countryKey of siteCountriesByLanguage[languageKey]) {
      result[countryKey] = result[countryKey] || []
      result[countryKey].push(languageKey)
    }
  }

  return result
})()

module.exports = { siteCountriesByLanguage, siteLanguagesByCountries }
