import React from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  COLOR_TEXT_DISABLED,
  COLOR_TEXT_INVERTED,
  COLOR_TEXT_PRIMARY,
  COLOR_UI_BACKGROUND_DISABLED,
  COLOR_UI_BACKGROUND_INVERTED,
  COLOR_UI_BACKGROUND_PRIMARY,
  TEXT_TOKEN_BUTTON_BREAKPOINT_MD,
  useTheme,
} from "../../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"
import {
  COLOR_BLUE,
  COLOR_GRAY_100,
  COLOR_GRAY_900,
  COLOR_GREEN,
  COLOR_STONE,
  COLOR_SUCCESS,
  SPACE_M,
  SPACE_S,
  SPACE_TOKEN_STACK_1XL,
  SPACE_TOKEN_STACK_S,
} from "../../styles/primitives"
import { Link } from "../links"
import { Text } from "../Text"
import { useHoverState } from "../useHoverState"
import { ColorEffect } from "./ColorEffect"
import {
  BORDER_RADIUS_ROUNDED_PX,
  BORDER_RADIUS_NOT_ROUNDED_PX,
} from "./config"

const SColorEffect = styled(ColorEffect)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: inherit;
`

const disabledStyle = {
  backgroundColor: COLOR_UI_BACKGROUND_DISABLED,
  foregroundColor: COLOR_TEXT_DISABLED,
  activeForegroundColor: COLOR_TEXT_DISABLED,
  filled: true,
  activeEffectColorSet: [],
}

const buttonStyleMap = {
  primary: {
    backgroundColor: COLOR_UI_BACKGROUND_PRIMARY,
    foregroundColor: COLOR_TEXT_INVERTED,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: true,
    activeEffectColorSet: [COLOR_BLUE, COLOR_GREEN, COLOR_GRAY_900],
    disabled: disabledStyle,
  },
  primaryInverted: {
    backgroundColor: COLOR_STONE,
    foregroundColor: COLOR_UI_BACKGROUND_PRIMARY,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: true,
    activeEffectColorSet: [COLOR_BLUE, COLOR_GREEN, COLOR_GRAY_900],
    disabled: disabledStyle,
  },
  secondary: {
    backgroundColor: COLOR_GRAY_900,
    foregroundColor: COLOR_GRAY_900,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: false,
    activeEffectColorSet: [COLOR_GRAY_900],
    disabled: disabledStyle,
  },
  secondaryInverted: {
    backgroundColor: COLOR_UI_BACKGROUND_INVERTED,
    foregroundColor: COLOR_UI_BACKGROUND_INVERTED,
    activeForegroundColor: "transparent",
    filled: false,
    activeEffectColorSet: [COLOR_UI_BACKGROUND_INVERTED],
    disabled: {
      ...disabledStyle,
      backgroundColor: COLOR_UI_BACKGROUND_INVERTED,
      foregroundColor: COLOR_UI_BACKGROUND_INVERTED,
      activeForegroundColor: COLOR_UI_BACKGROUND_INVERTED,
    },
  },
  tertiary: {
    backgroundColor: COLOR_STONE,
    foregroundColor: COLOR_UI_BACKGROUND_PRIMARY,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: true,
    activeEffectColorSet: [
      COLOR_BLUE,
      COLOR_UI_BACKGROUND_PRIMARY,
      COLOR_GRAY_900,
    ],
    disabled: disabledStyle,
  },
  tertiaryInverted: {
    backgroundColor: COLOR_STONE,
    foregroundColor: COLOR_UI_BACKGROUND_PRIMARY,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: true,
    activeEffectColorSet: [COLOR_BLUE, COLOR_GREEN, COLOR_GRAY_900],
    disabled: disabledStyle,
  },
  success: {
    backgroundColor: COLOR_SUCCESS,
    foregroundColor: COLOR_TEXT_INVERTED,
    activeForegroundColor: COLOR_SUCCESS,
    filled: true,
    activeEffectColorSet: [COLOR_GRAY_900],
    disabled: disabledStyle,
  },
  ghost: {
    backgroundColor: COLOR_STONE,
    foregroundColor: COLOR_UI_BACKGROUND_PRIMARY,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: true,
    activeEffectColorSet: [COLOR_UI_BACKGROUND_PRIMARY],
    disabled: disabledStyle,
  },
  greenChoiceActive: {
    backgroundColor: COLOR_GREEN,
    foregroundColor: COLOR_GRAY_100,
    activeForegroundColor: COLOR_GREEN,
    filled: true,
    activeEffectColorSet: [COLOR_GRAY_100],
    disabled: disabledStyle,
  },
  greenChoiceInactive: {
    backgroundColor: COLOR_GRAY_100,
    foregroundColor: COLOR_TEXT_PRIMARY,
    activeForegroundColor: COLOR_TEXT_INVERTED,
    filled: true,
    activeEffectColorSet: [COLOR_GREEN],
    disabled: disabledStyle,
  },
}

const minWidthStyles = breakpoints({
  SM: css`
    min-width: 200px;
  `,
  MD: css`
    min-width: 286px;
  `,
  LG: css`
    min-width: 286px;
  `,
  XL: css`
    min-width: 286px;
  `,
})

const StyledButton = styled.button`
  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border-radius: ${({ rounded }) =>
    rounded ? BORDER_RADIUS_ROUNDED_PX : BORDER_RADIUS_NOT_ROUNDED_PX}px;
  height: ${SPACE_TOKEN_STACK_1XL}px;
  transition: color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  appearance: none;
  background: none;
  border: none;

  &:hover {
    ${SColorEffect} {
      * {
        animation-play-state: running;
      }
    }
  }

  ${({ isContainedInLink }) => isContainedInLink || minWidthStyles};

  ${breakpoints({
    SM: css`
      height: ${SPACE_TOKEN_STACK_1XL - SPACE_TOKEN_STACK_S}px;
    `,
    LG: css`
      font-size: ${TEXT_TOKEN_BUTTON_BREAKPOINT_MD.fontSize}rem;
    `,
  })}

  ${({ buttonStyle, appearDisabled }) =>
    appearDisabled
      ? createCSSFromButtonStyle(buttonStyle.disabled)
      : createCSSFromButtonStyle(buttonStyle)};
`

function createCSSFromButtonStyle(buttonStyle) {
  const activeForegroundColor =
    buttonStyle.activeForegroundColor === "transparent"
      ? ({ theme }) => theme.backgroundColor
      : buttonStyle.activeForegroundColor

  return css`
    color: ${buttonStyle.foregroundColor};
    stroke: ${buttonStyle.foregroundColor};

    svg {
      .colored-fg-stroke {
        stroke: ${buttonStyle.foregroundColor};
      }
      .colored-fg-fill {
        fill: ${buttonStyle.foregroundColor};
      }
    }

    &:hover {
      color: ${activeForegroundColor};

      svg {
        .colored-fg-stroke {
          stroke: ${activeForegroundColor};
        }
        .colored-fg-fill {
          fill: ${activeForegroundColor};
        }
      }
    }
  `
}

const StyledText = styled(Text)`
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  > * ~ * {
    margin-left: ${SPACE_S}px;
  }
`

const StyledLink = styled(Link)`
  display: block;
  width: fit-content;

  ${minWidthStyles};

  > ${StyledButton} {
    width: 100%;
    padding: 0 ${SPACE_M}px;
  }
`

const variantInversions = {
  primary: "primaryInverted",
  secondary: "secondaryInverted",
  tertiary: "tertiaryInverted",
}

export function Button({
  variant,
  disabled = false,
  rounded = true,
  children,
  linkTo,
  target,
  ...otherProps
}) {
  const { theme } = useTheme()
  const { isHovered, ...hoverStateProps } = useHoverState()
  const actualVariant = theme.invertedButtons
    ? variantInversions[variant] || variant
    : variant
  const buttonStyle = buttonStyleMap[actualVariant]

  const buttonContent = (
    <StyledButton
      appearDisabled={disabled}
      disabled={disabled}
      buttonStyle={buttonStyle}
      rounded={rounded}
      {...hoverStateProps}
      isContainedInLink={!!linkTo}
      {...(linkTo ? {} : otherProps)}
    >
      <SColorEffect
        state={isHovered ? "active" : "initial"}
        colors={
          disabled
            ? buttonStyle.disabled.activeEffectColorSet
            : buttonStyle.activeEffectColorSet
        }
        defaultColor={
          disabled
            ? buttonStyle.disabled.backgroundColor
            : buttonStyle.backgroundColor
        }
        borderWidth={buttonStyle.filled ? null : 2}
      />
      <StyledText type="button">{children}</StyledText>
    </StyledButton>
  )

  if (linkTo) {
    return (
      <StyledLink to={linkTo} target={target} {...otherProps}>
        {buttonContent}
      </StyledLink>
    )
  }

  return buttonContent
}
