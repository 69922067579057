import { graphql } from "gatsby"
import { createContext, useContext } from "react"

export const globalValuesFragment = graphql`
  fragment GlobalValues on Query {
    contentfulGlobal(
      slug: { eq: "global-values" }
      node_locale: { eq: $node_locale }
    ) {
      loadMoreButtonLabel
      publishedAtLabel
      colorsLabel
      powerLabel
      validationErrorHeadline
      fieldIsRequiredValidation
      emailValidationErrorText
      validationErrorNumberMinimum
      validationErrorPhoneNumberLocalPart
      validationErrorInvalidEmailFormat
      genericErrorMessage
      languages
      countries
    }
  }
`

const globalValuesContext = createContext()

export const GlobalValuesProvider = globalValuesContext.Provider

export function useGlobalTranslation(field, variables = {}) {
  const localizedGlobalValues = useContext(globalValuesContext)

  return interpolateVariablesInTranslation(
    localizedGlobalValues[field],
    variables
  )
}

export function interpolateVariablesInTranslation(
  rawTranslationText,
  variables
) {
  let translatedValue = rawTranslationText

  for (const key in variables) {
    translatedValue = translatedValue.replace(`{${key}}`, variables[key])
  }

  return translatedValue
}
