import React from "react"

const Trustbadge = ({ width }) => {
  React.useLayoutEffect(() => {
    var _tsid = "X19D06733F2D25E07EA6C0867FB8D64BC"
    window._tsConfig = {
      variant: "custom_reviews",
      customElementId: "trustbadge",
      disableResponsive: "true",
    }
    var _ts = document.createElement("script")
    _ts.type = "text/javascript"
    _ts.async = true
    _ts.src = "//widgets.trustedshops.com/js/" + _tsid + ".js"
    var __ts = document.getElementsByTagName("script")[0]
    __ts.parentNode.insertBefore(_ts, __ts)
  }, [])

  return (
    <div
      id="trustbadge"
      style={{ width: width ?? 150, height: width / 2 ?? 75 }}
    ></div>
  )
}

export default Trustbadge
