import React, { useCallback, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Button } from "./button"
import { breakpoints } from "../styles"
import GermanFlag from "../images/flag_germany.svg"
import AustrianFlag from "../images/flag_austria.svg"
import FrenchFlag from "../images/flag_france.svg"
import DutchFlag from "../images/flag_netherlands.svg"
import { LocaleModal } from "./locale"
import { useLocale } from "../locale"
import { navigate } from "gatsby-link"
import { getLocalizedNodeLink } from "./locale"

export function ChangeLocaleButton({
  localizedNodes,
  mainData,
  ...otherProps
}) {
  const { footerCountrySelectionLabel } = mainData
  const [isLocaleModalOpen, setIsLocaleModalOpen] = useState(false)

  const handleCountrySelectorClick = useCallback(
    () => setIsLocaleModalOpen(true),
    []
  )
  const handleLocaleModalRequestClose = useCallback(
    () => setIsLocaleModalOpen(false),
    []
  )

  const { country } = useLocale()

  useEffect(() => {
    console.log("Boot language")
    const country =
      document.cookie
        .split(";")
        .find(c => c.trim().split("=")[0] === "unu_country") ?? "unu_country=de"
    const language =
      document.cookie
        .split(";")
        .find(c => c.trim().split("=")[0] === "unu_language") ??
      "unu_language=de"

    if (country && language) {
      let countryKey = country.split("=")[1]
      let languageKey = language.split("=")[1]
      // Sanitize since weglot allows combinations unknown to this site
      switch (countryKey) {
        case "de":
          if (languageKey !== "de" && languageKey !== "en") languageKey = "de"
          break
        case "at":
          if (languageKey !== "de") languageKey = "de"
          break
        case "fr":
          if (languageKey !== "fr") languageKey = "fr"
          break
        case "nl":
          if (languageKey !== "nl" && languageKey !== "en") languageKey = "nl"
          break
        default:
          countryKey = "de"
          languageKey = "de"
          break
      }
      document.cookie = `unu_country=${countryKey}; domain=unumotors.com; path=/`
      document.cookie = `unu_language=${languageKey}; domain=unumotors.com; path=/`

      const slugPrefix = `${languageKey}-${countryKey}`
      if (
        !window.location.pathname.match(
          new RegExp(`^/${languageKey}-${countryKey}/.*`)
        )
      ) {
        const node = (
          getLocalizedNodeLink(localizedNodes, languageKey, countryKey) ||
          slugPrefix
        ).replace("#", `/${slugPrefix}`)
        console.log(
          `Nav to ${languageKey}-${countryKey}`,
          localizedNodes,
          node,
          window.location.hash,
          window.location.search
        )
        navigate(`${node}${window.location.hash}${window.location.search}`)
      }
    }
  }, [])

  return (
    <>
      {isLocaleModalOpen && (
        <LocaleModal
          onRequestClose={handleLocaleModalRequestClose}
          mainData={mainData}
          localizedNodes={localizedNodes}
        />
      )}

      <Root onClick={handleCountrySelectorClick} {...otherProps}>
        <SButton variant="secondaryInverted">
          <span>{footerCountrySelectionLabel}</span>
          <img
            src={flagImagesByCountry[country]}
            alt={`Flag for ${country.toUpperCase()}`}
          />
        </SButton>
      </Root>
    </>
  )
}

const flagImagesByCountry = {
  de: GermanFlag,
  at: AustrianFlag,
  fr: FrenchFlag,
  nl: DutchFlag,
}

const Root = styled.div``

const SButton = styled(Button)`
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  height: 48px;
  width: 100%;
  min-width: 0;

  & span {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  ${breakpoints({
    SM: css`
      width: 100%;
    `,
    MD: css`
      width: 100%;
    `,
    LG: css``,
    XL: css``,
  })}
`
