import React, { useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import { linkStyles } from "./linkStyles"
import { useAsyncValue } from "../../useAsyncValue"

export const Anchor = styled.a`
  cursor: pointer;
  ${linkStyles};
`

export function ModalLink({ modalEntry, children, ...otherProps }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClick = useCallback(event => {
    event.preventDefault()
    setIsModalOpen(true)
  }, [])
  const handleClose = useCallback(event => {
    setIsModalOpen(false)
  }, [])

  const resultContentfulModal = useAsyncValue(
    useMemo(() => import("../ContentfulModal"), [])
  )

  return (
    <>
      <Anchor onClick={handleClick}>{children}</Anchor>
      {isModalOpen && resultContentfulModal.type === "RESOLVED" && (
        <resultContentfulModal.value.ContentfulModal
          headline={modalEntry.headline}
          content={modalEntry.content}
          onClose={handleClose}
        />
      )}
    </>
  )
}
