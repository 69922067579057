const { useEffect, useState, useMemo } = require("react")

export function useAsyncValue(promise) {
  const [finalState, setFinalState] = useState(null)

  useEffect(() => {
    if (!promise) {
      return
    }

    promise
      .then(value => {
        setFinalState({ type: "RESOLVED", value })
      })
      .catch(error => {
        setFinalState({ type: "REJECTED", error })
      })
  }, [promise])

  return useMemo(
    () =>
      finalState
        ? finalState
        : promise === null
        ? { type: "NONE" }
        : { type: "PENDING" },
    [finalState, promise]
  )
}
