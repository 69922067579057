import React, { forwardRef, useCallback } from "react"
import { ExternalLink } from "./links"
import styled from "styled-components"
import { COLOR_UI_BACKGROUND_DISABLED } from "../styles/tokens"
import { SPACE_TOKEN_STACK_2XL } from "../styles/primitives"
import { Image } from "./Image"
import { Video } from "./videos"
import { LoadingComponentDecorator } from "./LoadingComponentDecorator"

const EmbedBox = styled.div`
  background-color: ${COLOR_UI_BACKGROUND_DISABLED};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SPACE_TOKEN_STACK_2XL}px;
`

export const Media = forwardRef(({ media, ...otherProps }, ref) => {
  if (media.__typename === "ContentfulVideo") {
    return <SVideo videoEntry={media} {...otherProps} ref={ref} />
  }

  if (media.gatsbyImageData) {
    return <SImage asset={media} {...otherProps} ref={ref} />
  }

  return (
    <EmbedBox {...otherProps} ref={ref}>
      <ExternalLink href={`http:${media.file.url}`}>{media.title}</ExternalLink>
    </EmbedBox>
  )
})

export const LoadingDecoratedMedia = forwardRef(
  ({ avoidColors, className, ...otherProps }, ref) => {
    return (
      <LoadingComponentDecorator
        avoidColors={avoidColors}
        className={className}
        ref={ref}
      >
        {renderProps => (
          <LoadingDecoratorChildren renderProps={renderProps} {...otherProps} />
        )}
      </LoadingComponentDecorator>
    )
  }
)

function LoadingDecoratorChildren({
  renderProps,
  onLoad: customOnLoad,
  onError: customOnError,
  ...otherProps
}) {
  const handleLoad = useCallback(
    (...args) => {
      customOnLoad?.(...args)
      renderProps.onLoad(...args)
    },
    [customOnLoad, renderProps]
  )
  const handleError = useCallback(
    (...args) => {
      customOnError?.(...args)
      renderProps.onError(...args)
    },
    [customOnError, renderProps]
  )

  return <Media onLoad={handleLoad} onError={handleError} {...otherProps} />
}

const SVideo = styled(Video)`
  width: 100%;
  height: 100%;
`

const SImage = styled(Image)`
  width: 100%;
  height: 100%;
`
