import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import throttle from "throttleit"
import styled, { css } from "styled-components"
import { PlayIcon, PauseIcon } from "../../icons/icons"
import { breakpoints } from "../../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"
import { SPACE_XL } from "../../styles/primitives"

const playPauseIconStyles = css`
  position: absolute;
  ${breakpoints({
    SM: css`
      width: 80px;
      height: 80px;
      left: 50%;
    `,
    MD: css`
      width: 80px;
      height: 80px;
    `,
    LG: css`
      width: 100px;
      height: 100px;
    `,
    XL: css`
      width: 100px;
      height: 100px;
    `,
  })}

  ${({ centerControls }) =>
    centerControls
      ? css`
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `
      : breakpoints({
          SM: css`
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          `,
          MD: css`
            bottom: ${SPACE_XL}px;
            left: ${SPACE_XL}px;
          `,
          LG: css`
            bottom: ${SPACE_XL}px;
            left: ${SPACE_XL}px;
          `,
          XL: css`
            bottom: ${SPACE_XL}px;
            left: ${SPACE_XL}px;
          `,
        })}

  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
`

const StyledPlayIcon = styled(PlayIcon)`
  ${playPauseIconStyles};

  opacity: 1;

  ${({ videoState }) =>
    videoState.isPlaying &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`

const StyledPauseIcon = styled(PauseIcon)`
  ${playPauseIconStyles};

  ${({ videoState, isDisplayed }) =>
    isDisplayed
      ? css`
          opacity: 1;
          pointer-events: none;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

const Container = styled.div`
  position: relative;
`
const StyledVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const HostedVideo = forwardRef(
  ({ videoEntry, autoPlay, centerControls, onLoad, ...otherProps }, ref) => {
    const [videoState, setVideoState] = useState({ isPlaying: false })
    const { isPauseIconDisplayed, onMouseMovePlayer } = usePauseIconState()
    const { assetMp4, muted, loop, disableControls } = videoEntry

    const videoRef = useRef()
    const videoPlayPausePromiseRef = useRef(Promise.resolve())

    const scheduleAsyncVideoPlayPause = useCallback(async nextOperation => {
      const previousOperation = videoPlayPausePromiseRef.current
      videoPlayPausePromiseRef.current = (async () => {
        await previousOperation
        return nextOperation()
      })()
    }, [])

    useLayoutEffect(() => {
      const videoElement = videoRef.current

      const onPlay = () =>
        setVideoState(current => ({ ...current, isPlaying: true }))
      const onPause = () =>
        setVideoState(current => ({ ...current, isPlaying: false }))

      videoElement.addEventListener("play", onPlay)
      videoElement.addEventListener("pause", onPause)

      return () => {
        videoElement.removeEventListener("play", onPlay)
        videoElement.removeEventListener("pause", onPause)
      }
    }, [])

    const play = useCallback(() => {
      scheduleAsyncVideoPlayPause(() => videoRef.current.play())
    }, [scheduleAsyncVideoPlayPause])
    const pause = useCallback(() => {
      scheduleAsyncVideoPlayPause(() => videoRef.current.pause())
    }, [scheduleAsyncVideoPlayPause])
    const playPause = useCallback(() => {
      if (disableControls) {
        return
      }
      videoState.isPlaying ? pause() : play()
    }, [disableControls, pause, play, videoState.isPlaying])

    useLayoutEffect(() => {
      onLoad?.({ play, pause })
    }, [onLoad, pause, play, playPause])

    return (
      <Container {...otherProps} onPointerMove={onMouseMovePlayer} ref={ref}>
        <StyledVideo
          autoPlay={autoPlay}
          muted={muted}
          onClick={playPause}
          ref={videoRef}
          loop={!!loop}
          playsInline={true}
        >
          <source src={assetMp4.file.url} type="video/mp4" />
        </StyledVideo>
        {disableControls || (
          <>
            <StyledPlayIcon
              videoState={videoState}
              onClick={playPause}
              centerControls={centerControls}
            />
            <StyledPauseIcon
              onClick={playPause}
              videoState={videoState}
              isDisplayed={videoState.isPlaying && isPauseIconDisplayed}
              centerControls={centerControls}
            />
          </>
        )}
      </Container>
    )
  }
)

function usePauseIconState() {
  const [pauseIconDisplayTimestamp, setPauseIconDisplayTimestamp] = useState(
    null
  )

  const onMouseMovePlayer = useMemo(
    () =>
      throttle(() => {
        setPauseIconDisplayTimestamp(Date.now())
      }, 50),
    []
  )

  const onTouchPlayer = useCallback(
    () => setPauseIconDisplayTimestamp(Date.now()),
    []
  )

  useEffect(() => {
    if (!pauseIconDisplayTimestamp) {
      return
    }

    const timeoutId = setTimeout(() => {
      setPauseIconDisplayTimestamp(null)
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [pauseIconDisplayTimestamp])

  return {
    isPauseIconDisplayed: !!pauseIconDisplayTimestamp,
    onMouseMovePlayer,
    onTouchPlayer,
  }
}
