import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocale } from "../../locale"

export function useLocaleDecision() {
  const [isSuggestionDismissed, setIsSuggestionDismissed] = useState(null)
  const [browserLanguage, setBrowserLanguage] = useState(null)
  const { language } = useLocale()

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    setBrowserLanguage(window.navigator.language.toLowerCase())
    setIsSuggestionDismissed(localeSuggestionDismissItemAPI.getItem() || false)
  }, [])

  const dismissSuggestion = useCallback(() => {
    setIsSuggestionDismissed(true)
    localeSuggestionDismissItemAPI.setItem(true)
  }, [])

  return useMemo(
    () => ({
      shouldSuggestDifferentLocale:
        typeof browserLanguage === "string" && isSuggestionDismissed !== null
          ? isSuggestionDismissed
            ? false
            : !browserLanguage.startsWith(language)
          : false,
      dismissSuggestion,
    }),
    [browserLanguage, dismissSuggestion, isSuggestionDismissed, language]
  )
}

function createLocaleStorageItemAPI(key) {
  if (typeof localStorage === "undefined") {
    return { getItem: () => null, setItem: () => {} }
  }
  return {
    getItem: () => JSON.parse(localStorage.getItem(key || "null")),
    setItem: value => localStorage.setItem(key, JSON.stringify(value)),
  }
}

export const localeSuggestionDismissItemAPI = createLocaleStorageItemAPI(
  "localeSuggestionDismissed"
)
