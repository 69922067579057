/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useMemo } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { siteCountriesByLanguage } from "../config"
import { getLocalizedNodeLink } from "./locale"
import { useLocale } from "../locale"

const defaultPageMeta = {
  canonicalTag: null,
  robots: {
    "max-image-preview": "large",
    "max-snippet": "-1",
    "max-video-preview": "-1",
    noindex: false,
    nofollow: false,
  },
}

const defaultMetaTags = [
  {
    property: `og:type`,
    content: `website`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
]

function SEO({ description, pageMeta, title, localizedNodes }) {
  const { language, country } = useLocale()
  const { unuSVGLogoFile, site } = useStaticQuery(
    graphql`
      query {
        unuSVGLogoFile: file(relativePath: { eq: "unu-logo.svg" }) {
          publicURL
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const hyphenatedPageMetaRobots = useMemo(
    () =>
      Object.keys(pageMeta?.robots || {}).reduce(
        (result, key) => ({
          ...result,
          [key.replace(/_/g, "-")]: pageMeta.robots[key],
        }),
        {}
      ),
    [pageMeta?.robots]
  )

  const finalMeta = useMemo(
    () => ({
      ...defaultPageMeta,
      ...pageMeta,
      robots: { ...defaultPageMeta.robots, ...hyphenatedPageMetaRobots },
    }),
    [hyphenatedPageMetaRobots, pageMeta]
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const alternateLangHrefLinks = useMemo(() => {
    const result = []
    function addNodeAsAlternateLink(href, hrefLang) {
      if (!href) {
        return
      }
      result.push({
        rel: "alternate",
        href: new URL(href, process.env.GATSBY_SITE_URL).href,
        hrefLang,
      })
    }
    for (const languageKey in siteCountriesByLanguage) {
      for (const countryKey of siteCountriesByLanguage[languageKey]) {
        const href = getLocalizedNodeLink(
          localizedNodes,
          languageKey,
          countryKey
        )
        addNodeAsAlternateLink(href, `${languageKey}-${countryKey}`)
      }
    }

    const defaultLanguageKey = Object.keys(siteCountriesByLanguage)[0]
    const defaultCountryKey = siteCountriesByLanguage[defaultLanguageKey][0]
    const href = getLocalizedNodeLink(
      localizedNodes,
      defaultLanguageKey,
      defaultCountryKey
    )
    addNodeAsAlternateLink(href, "x-default")
    return result
  }, [localizedNodes])

  const canonicalMetaTag = useMemo(() => {
    if (hyphenatedPageMetaRobots.noindex) {
      return null
    }
    const canonicalPath =
      pageMeta?.canonicalTag ||
      getLocalizedNodeLink(localizedNodes, language, country)
    return canonicalPath
      ? {
          name: "canonical",
          content: `${process.env.GATSBY_SITE_URL}${canonicalPath}`,
        }
      : null
  }, [
    country,
    hyphenatedPageMetaRobots.noindex,
    language,
    localizedNodes,
    pageMeta?.canonicalTag,
  ])

  let locale = getLocale(language, country)

  const metaTags = useMemo(() => {
    return [
      ...defaultMetaTags,
      ...(canonicalMetaTag ? [canonicalMetaTag] : []),
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata?.author || ``,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
      ...Object.keys(finalMeta.robots).map(key =>
        finalMeta.robots[key] === false
          ? null
          : {
              name: "robots",
              content:
                finalMeta.robots[key] === true
                  ? key
                  : `${key}:${finalMeta.robots[key]}`,
            }
      ),
    ].filter(Boolean)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalMeta.robots, metaDescription, site.siteMetadata?.author, title])

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={alternateLangHrefLinks}
      meta={metaTags}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          url: process.env.GATSBY_SITE_URL,
          logo: unuSVGLogoFile.publicURL,
        })}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
}

export default SEO

function getLocale(language, country) {
  if (language === "fr" && country === "fr") {
    return "fr-FR"
  }
  if (language === "de" && country === "de") {
    return "de-DE"
  }
  if (language === "de" && country === "at") {
    return "de-AT"
  }
  if (language === "en" && country === "de") {
    return "en"
  }
  if (language === "en" && country === "nl") {
    return "en-NL"
  }
  if (language === "nl" && country === "nl") {
    return "nl-NL"
  }

  return "en"
}
