/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"
import Header from "./Header"
import { useEffect } from "react"
import Footer from "./Footer"
import { LocaleProvider } from "../locale"

const Layout = ({ children, mainData, language, country, localizedNodes, logoOnlyHeader }) => {
  const [showMenu, setShowMenu] = useState(false)
  const slugPrefix = `${language}-${country}`

  useEffect(() => {
    // locks body scroll when menu is open
    if (showMenu) {
      document.body.classList.add("lockScroll")
    } else {
      document.body.classList.remove("lockScroll")
    }
  }, [showMenu])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  function toggleMenu() {
    setShowMenu(!showMenu)
  }

  return (
    <LocaleProvider language={language} country={country}>
      <LayoutContainer>
        <SHeader
          siteTitle={data.site.siteMetadata?.title || `unu`}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
          slugPrefix={slugPrefix}
          mainData={mainData}
          localizedNodes={localizedNodes}
          logoOnlyHeader={logoOnlyHeader}
        />
        <SMainContainer>{children}</SMainContainer>
        <Footer
          country={country}
          language={language}
          slugPrefix={slugPrefix}
          mainData={mainData}
          localizedNodes={localizedNodes}
        />
      </LayoutContainer>
    </LocaleProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const SMainContainer = styled.div`
  flex: 1 0 auto;
  z-index: 0;
`

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const SHeader = styled(Header)`
  z-index: 1;
`
