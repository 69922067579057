import React from "react"
import styled, { css } from "styled-components"
import {
  COLOR_GRAY_900,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"
import { breakpoints, ThemeProvider } from "../../styles"
import { RichText } from "../RichText"
import { LightCloseIcon } from "../../icons/icons"
import { ChangeLocaleButton } from "../ChangeLocaleButton"
import { Text } from "../Text"
import { MAX_WIDTH } from "../../styles/breakpoints"

export function LocaleSuggestionBar({
  localizedNodes,
  mainData,
  onRequestClose,
  onDismiss,
}) {
  const { localeSuggestionOverlayText } = mainData
  return (
    <ThemeProvider themeName="dark">
      <Root>
        <DescriptionText type="copy">
          <RichText data={localeSuggestionOverlayText} />
        </DescriptionText>
        <SLightCloseIcon onClick={onDismiss} />
        <SChangeLocaleButton
          mainData={mainData}
          localizedNodes={localizedNodes}
        />
      </Root>
    </ThemeProvider>
  )
}

const Root = styled.div`
  display: grid;
  grid-column-gap: ${SPACE_L}px;
  background-color: ${COLOR_GRAY_900};
  align-items: center;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_L}px ${SPACE_L}px ${SPACE_XL}px;
      grid-template-areas:
        "text close"
        "button button";
      grid-row-gap: ${SPACE_L}px;
    `,
    MD: css`
      grid-template-areas: "text button . close";
      grid-template-columns: minmax(auto, max-content) max-content auto min-content;
      grid-template-rows: auto;
      padding: ${SPACE_M}px ${SPACE_XL}px;
    `,
    LG: css`
      grid-template-areas: "text button . close";
      grid-template-columns: minmax(auto, max-content) max-content auto min-content;
      grid-template-rows: auto;
      padding: ${SPACE_M}px ${SPACE_XL}px;
    `,
    XL: css`
      grid-template-areas: "text button . close";
      grid-template-columns: minmax(auto, max-content) max-content auto min-content;
      grid-template-rows: auto;
      padding: ${SPACE_M}px
        calc(max(100vw - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px);
    `,
  })}
`

const DescriptionText = styled(Text)`
  grid-area: text;
`

const SLightCloseIcon = styled(LightCloseIcon)`
  grid-area: close;
  margin-left: auto;
  align-self: flex-start;
  width: 16px;
  height: 16px;
`

const SChangeLocaleButton = styled(ChangeLocaleButton)`
  grid-area: button;
`
