import React, { forwardRef, useCallback, useMemo, useRef } from "react"

import styled, { css, keyframes } from "styled-components"
import { useTheme } from "../styles"
import { ANIMATION_EASING } from "../styles/animations"
import {
  COLOR_BLUE,
  COLOR_CORAL,
  COLOR_GREEN,
  COLOR_STONE,
} from "../styles/primitives"

const ANIMATION_DURATION = 750

export const LoadingComponentDecorator = forwardRef(
  (
    {
      children,
      avoidColors,
      colorShift = 0,
      onLoad: customOnLoad,
      onReveal,
      autoScale = true,
      ...otherProps
    },
    ref
  ) => {
    const { theme } = useTheme()
    const realAvoidColors = useMemo(
      () => avoidColors || [theme.backgroundColor],
      [avoidColors, theme.backgroundColor]
    )
    const colorCycle = useMemo(() => {
      const allowedColors = [
        COLOR_BLUE,
        COLOR_GREEN,
        COLOR_CORAL,
        COLOR_STONE,
      ].filter(color => !realAvoidColors.includes(color))
      allowedColors.unshift(
        ...allowedColors.splice(
          allowedColors.length - (colorShift % allowedColors.length),
          allowedColors.length
        )
      )
      return allowedColors
    }, [colorShift, realAvoidColors])
    const keyframesSet = useMemo(
      () =>
        colorCycle.map((color, index) =>
          createSlideInFromLeftKeyframes(colorCycle.length, index)
        ),
      [colorCycle]
    )

    const realStateRef = useRef("loading")

    const onLoad = useCallback(
      (...args) => {
        realStateRef.current = "loaded"
        customOnLoad?.(...args)
      },
      [customOnLoad]
    )
    const onError = useCallback(() => {}, [])

    const renderProps = useMemo(() => ({ onLoad, onError }), [onError, onLoad])
    const renderedChildren = useMemo(() => children(renderProps), [
      children,
      renderProps,
    ])
    const boxElementRefs = useRef([])

    const handleAnimationIteration = useCallback(event => {
      if (realStateRef.current === "loading") {
        return
      }

      boxElementRefs.current.forEach((elementRef, index) => {
        const nextBoxElement =
          boxElementRefs.current[
            index === boxElementRefs.current.length - 1 ? 0 : index + 1
          ]

        if (nextBoxElement === event.target) {
          elementRef.style.animationPlayState = "paused"
        } else {
          elementRef.style.animationIterationCount = "1"
        }
      })
      imageWrapperElementRef.current.style.animationPlayState = "running"
    }, [])

    const rectangles = useMemo(() => {
      return colorCycle.map((color, index) => ({
        key: index,
        keyframes: keyframesSet[index],
        style: {
          backgroundColor: color,
          animationDuration: `${ANIMATION_DURATION * colorCycle.length}ms`,
          animationDelay: `${index * ANIMATION_DURATION}ms`,
        },
        ref: element => {
          boxElementRefs.current[index] = element
        },
        onAnimationIteration: event => handleAnimationIteration(event, index),
      }))
    }, [colorCycle, handleAnimationIteration, keyframesSet])

    const imageWrapperElementRef = useRef()

    const handleImageAnimationEnd = useCallback(() => {
      boxElementRefs.current.forEach((elementRef, index) => {
        // Sometimes, sub-pixel rendering causes thin lines of the loading rectangles to shine
        // through. Since we do not need them anymore, hide them for sure.
        elementRef.style.visibility = "hidden"
      })
      onReveal?.()
    }, [onReveal])

    return (
      <Root {...otherProps} ref={ref}>
        {rectangles.map(({ key, keyframes, style, ref }) => (
          <AnimatedBox
            key={key}
            id={`key${key}`}
            keyframes={keyframes}
            style={style}
            ref={ref}
            onAnimationIteration={handleAnimationIteration}
            autoScale={autoScale}
          />
        ))}
        <ImageWrapper
          ref={imageWrapperElementRef}
          onAnimationEnd={handleImageAnimationEnd}
          autoScale={autoScale}
        >
          {renderedChildren}
        </ImageWrapper>
      </Root>
    )
  }
)

function createSlideInFromLeftKeyframes(count, index) {
  const cycle = 100 / count
  return keyframes`
    0% {
      transform: translateX(-100%);
      z-index: 100;
    }
    ${cycle}% {
      transform: translateX(0%);
      z-index: 100;
    }
    100% {
      z-index: ${index + 1};
      transform: translateX(0%);
    }
  `
}

const positionStyles = ({ autoScale }) =>
  autoScale
    ? css`
        grid-area: box;
      `
    : css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      `

const Root = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-areas: "box";

  transform: translate3d(0, 0, 0);
`

const AnimatedBox = styled.div`
  ${positionStyles};

  animation-timing-function: ${ANIMATION_EASING};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-name: ${({ keyframes }) => keyframes};
  transform: translateX(-100%);
`

const imageSlideInKeyframes = keyframes`
  0% {
    transform: translateX(0%);
  }
  0.00000000001% {
    transform: translateX(-100%);
    z-index: 100;
    opacity:1;
  }
  100% {
    transform: translateX(0%);
    z-index: 100;
    opacity:1;
  }
`

const ImageWrapper = styled.div`
  ${positionStyles};

  z-index: -1;
  opacity: 0;
  animation-timing-function: ${ANIMATION_EASING};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: ${ANIMATION_DURATION}ms;
  animation-play-state: paused;
  animation-name: ${imageSlideInKeyframes};
  will-change: opacity, transform;
`
