import styled, { css } from "styled-components"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"

export const SSwapTransition = styled.div`
  transition: transform ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  grid-area: content;
  transform-origin: center;
  transform: rotateX(90deg);

  ${({ isVisible }) =>
    isVisible &&
    css`
      transform: rotateX(0deg);
      transition-delay: ${ANIMATION_DURATION}ms;
    `};
`

export const swapTransitionParentStyles = css`
  display: grid;
  grid-template-areas: "content";
`
