import React from "react"

import { Button } from "./button"

export function CTAButton({ cta, variant = cta.theme, ...otherProps }) {
  return (
    <Button
      variant={variant}
      linkTo={resolveCTALink(cta)}
      target={resolveCTATarget(cta)}
      {...otherProps}
    >
      {cta.label}
    </Button>
  )
}

export function resolveCTALink(cta) {
  return cta.internalLink || cta.externalLink || cta.assetLink
}

export function resolveCTATarget(cta) {
  if (cta.externalLinkInNewTab === null || cta.externalLinkInNewTab) {
    return "_blank"
  } else {
    return "_self"
  }
}
