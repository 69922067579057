import React from "react"
import styled from "styled-components"
const { COLOR_STONE } = require("../../styles/primitives")

const Container = styled.div`
  max-width: none !important;
  margin-left: 0 !important;
  width: 100%;
  height: 100%;
  background: ${COLOR_STONE};
`

const SIFrame = styled.iframe`
  background: ${COLOR_STONE};
  border: none;
  width: 100%;
`

export function IFrame(props) {
  return (
    <Container>
      <SIFrame {...props} />
    </Container>
  )
}
