import { css } from "styled-components"
import { COLOR_INTERACTIVE_LINK } from "../styles"
import { COLOR_GRAY_300 } from "../styles/primitives"

export function createScrollbarStyles(borderRadius) {
  return css`
    scrollbar-width: thin;
    scrollbar-color: ${COLOR_GRAY_300} ${COLOR_INTERACTIVE_LINK};

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLOR_GRAY_300};
      border-radius: ${borderRadius};
    }

    &::-webkit-scrollbar-thumb {
      background: ${COLOR_INTERACTIVE_LINK};
      border-radius: ${borderRadius};
      border: none;
    }
  `
}
