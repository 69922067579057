import React from "react"
import { IFrame } from "./iFrame"

export const handleShortCodes = renderedComponents => {
  if (typeof renderedComponents === "string") {
    return selectShortCode(renderedComponents)
  } else if (
    renderedComponents.length === 1 &&
    typeof renderedComponents[0] === "string"
  ) {
    return selectShortCode(renderedComponents[0])
  } else if (renderedComponents.length > 1) {
    return renderedComponents.map(component => {
      if (
        component.props &&
        component.props.children &&
        component.props.children.map
      ) {
        component = {
          ...component,
          props: {
            ...component.props,
            children: component.props.children.map(handleShortCodes),
          },
        }
      }
      return component
    })
  }
  return renderedComponents
}

const selectShortCode = renderedComponents => {
  const regex = /\[(\w*)\s*(.*)\]/gs

  const matches = regex.exec(renderedComponents)
  if (matches) {
    switch (matches[1]) {
      case "IFRAME":
        return iFrameShortCode(matches[2])
    }
  }
  return renderedComponents
}

const iFrameShortCode = renderedComponents => {
  const regex = /(\w+)="(.+?)"/gs
  const args = renderedComponents.split(" ")
  const props = {}
  args.forEach(arg => {
    const matches = regex.exec(arg)
    if (matches.length === 3) {
      props[matches[1]] = matches[2]
    }
    regex.lastIndex = 0
  })

  return <IFrame {...props} />
}
