import React, { useLayoutEffect } from "react"
import { Helmet } from "react-helmet"

export function IubendaPolicyLink({
  iubendaURL,
  title,
  children,
  className = "",
  ...otherProps
}) {
  return (
    <a
      href={iubendaURL}
      className={`iubenda-nostyle no-brand iubenda-embed iubenda-noiframe ${className}`}
      title={title}
      {...otherProps}
    >
      {children}
      <Helmet>
        <script
          src="https://cdn.iubenda.com/iubenda.js"
          type="text/javascript"
          async={true}
        />
      </Helmet>
    </a>
  )
}
