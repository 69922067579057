import React from "react"
import styled from "styled-components"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"
import { COLOR_GRAY_900 } from "../styles/primitives"

const SVGRoot = styled.svg`
  path {
    transition: stroke ${ANIMATION_DURATION}ms ${ANIMATION_EASING},
      fill ${ANIMATION_DURATION}ms ${ANIMATION_EASING};
  }
`

export function CheckmarkIcon({ color = COLOR_GRAY_900, ...otherProps }) {
  return (
    <SVGRoot
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill={color}
      {...otherProps}
    >
      <path
        d="M6.00016 11.1701L1.83016 7.00009L0.410156 8.41009L6.00016 14.0001L18.0002 2.00009L16.5902 0.590088L6.00016 11.1701Z"
        className="colored-fg-stroke colored-fg-fill"
      />
    </SVGRoot>
  )
}
