import React from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "../../styles"

import { SPACE_2XL } from "../../styles/primitives"

const styles = css`
  list-style: none;
  margin: 0;

  & + * {
    ${breakpoints({
      SM: css`
        margin-top: ${SPACE_2XL}px;
      `,
      MD: css`
        margin-top: ${SPACE_2XL}px;
      `,
      LG: css`
        margin-top: ${SPACE_2XL}px;
      `,
      XL: css`
        margin-top: ${58}px;
      `,
    })}
  }
`
const OLContainer = styled.ol`
  ${styles}
`

const ULContainer = styled.ul`
  ${styles}
`

export function List({ isOrdered, ...ohterProps }) {
  return isOrdered ? (
    <OLContainer {...ohterProps} />
  ) : (
    <ULContainer {...ohterProps} />
  )
}
