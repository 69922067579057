import React from "react"
import styled, { css, keyframes } from "styled-components"
import { breakpoints } from "../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"
import {
  COLOR_CORAL,
  COLOR_DARK_CORAL,
  COLOR_GRAY_100,
  COLOR_GRAY_400,
  COLOR_GRAY_800,
  COLOR_GRAY_900,
  COLOR_STONE,
} from "../styles/primitives"

function createResponsiveStyles({ SM, MD, LG, XL }) {
  return css`
    ${breakpoints({
      SM: css`
        width: ${SM}px;
        height: ${SM}px;
      `,
      MD: css`
        width: ${MD}px;
        height: ${MD}px;
      `,
      LG: css`
        width: ${LG}px;
        height: ${LG}px;
      `,
      XL: css`
        width: ${XL}px;
        height: ${XL}px;
      `,
    })}
  `
}

const ResponsiveSVG24To44 = styled.svg`
  ${createResponsiveStyles({ SM: 24, MD: 32, LG: 32, XL: 44 })};
`

export function PlusIcon({ color, ...otherProps }) {
  return (
    <ResponsiveSVG24To44
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M23.8285 5.6788L20.1728 5.6788L20.1728 20.1721L5.67943 20.1721V23.8279L20.1728 23.8279L20.1728 38.3212L23.8285 38.3212L23.8285 23.8279L38.3218 23.8279V20.1721L23.8285 20.1721L23.8285 5.6788Z"
        fill={color}
      />
    </ResponsiveSVG24To44>
  )
}

export function MinusIcon({ color, ...otherProps }) {
  return (
    <ResponsiveSVG24To44
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M5.67943 20.1721V23.8279L38.3218 23.8279V20.1721L5.67943 20.1721Z"
        fill={color}
      />
    </ResponsiveSVG24To44>
  )
}

const DownloadIconSVG = styled.svg`
  ${createResponsiveStyles({ SM: 32, MD: 32, LG: 32, XL: 64 })};

  circle {
    fill: ${COLOR_CORAL};
    transition: fill ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  }

  &:hover circle {
    fill: ${COLOR_STONE};
  }

  path {
    stroke: ${COLOR_STONE};
    transition: stroke ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  }

  &:hover path {
    stroke: ${COLOR_CORAL};
  }
`

export function DownloadIcon({ ...otherProps }) {
  return (
    <DownloadIconSVG
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="32" cy="32" r="32" />
      <path d="M42 33.1484L32 43.1484L22 33.1484" strokeWidth="2" />
      <path d="M32 19L32 43.1" strokeWidth="2" />
    </DownloadIconSVG>
  )
}

function createInteractiveIconStyles(base, active) {
  return css`
    cursor: pointer;

    > .colored-bg {
      fill: ${base.backgroundColor};
      transition: fill ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
    }
    > .colored-fg {
      fill: ${base.foregroundColor};
      transition: fill ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
    }

    &:hover {
      > .colored-bg {
        fill: ${active.backgroundColor};
      }
      > .colored-fg {
        fill: ${active.foregroundColor};
      }
    }
  `
}

const InteractiveIconSecondarySVG = styled.svg`
  ${createInteractiveIconStyles(
    { backgroundColor: COLOR_GRAY_900, foregroundColor: COLOR_STONE },
    { backgroundColor: COLOR_CORAL, foregroundColor: COLOR_STONE }
  )}
`

const InteractiveIconPrimarySVG = styled.svg`
  ${createInteractiveIconStyles(
    { backgroundColor: COLOR_CORAL, foregroundColor: COLOR_STONE },
    { backgroundColor: COLOR_STONE, foregroundColor: COLOR_CORAL }
  )}
`

const interactiveIconDarkSVGStyles = createInteractiveIconStyles(
  { backgroundColor: "transparent", foregroundColor: COLOR_GRAY_900 },
  { backgroundColor: "transparent", foregroundColor: COLOR_CORAL }
)

export function LinkedInIcon({ ...otherProps }) {
  return (
    <InteractiveIconSecondarySVG
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        className="colored-bg"
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
      />
      <rect x="9" y="9" width="30" height="30" className="colored-fg" />
      <path
        className="colored-bg"
        d="M35.2655 35.2662H30.5241V27.8407C30.5241 26.07 30.4925 23.7906 28.0581 23.7906C25.5886 23.7906 25.2108 25.7198 25.2108 27.7118V35.2657H20.4694V19.9959H25.0211V22.0826H25.0848C25.5404 21.3038 26.1986 20.663 26.9895 20.2287C27.7803 19.7943 28.6742 19.5826 29.5758 19.616C34.3815 19.616 35.2675 22.7771 35.2675 26.8894L35.2655 35.2662ZM15.1195 17.9086C14.5753 17.9087 14.0433 17.7474 13.5908 17.4451C13.1382 17.1429 12.7855 16.7132 12.5772 16.2104C12.3688 15.7077 12.3142 15.1544 12.4203 14.6207C12.5264 14.0869 12.7883 13.5966 13.1731 13.2117C13.5578 12.8268 14.048 12.5646 14.5817 12.4584C15.1155 12.3521 15.6687 12.4065 16.1715 12.6147C16.6743 12.8228 17.1041 13.1754 17.4065 13.6279C17.7089 14.0803 17.8704 14.6123 17.8705 15.1565C17.8706 15.5178 17.7995 15.8757 17.6612 16.2095C17.523 16.5434 17.3204 16.8468 17.065 17.1023C16.8095 17.3579 16.5062 17.5606 16.1724 17.6989C15.8386 17.8373 15.4808 17.9085 15.1195 17.9086ZM17.4902 35.2662H12.7439V19.9959H17.4902V35.2662ZM37.6293 8.00218H10.3613C9.74241 7.9952 9.14602 8.23422 8.70325 8.66672C8.26048 9.09923 8.00755 9.68983 8 10.3088V37.6908C8.00729 38.31 8.26008 38.901 8.70283 39.334C9.14559 39.7669 9.74211 40.0064 10.3613 39.9998H37.6293C38.2497 40.0076 38.8479 39.7688 39.2924 39.3358C39.7369 38.9029 39.9914 38.3112 40 37.6908V10.3068C39.9912 9.68663 39.7365 9.09535 39.292 8.66286C38.8475 8.23036 38.2494 7.99202 37.6293 8.0002"
      />
    </InteractiveIconSecondarySVG>
  )
}

export function TwitterIcon({ ...otherProps }) {
  return (
    <InteractiveIconSecondarySVG
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        className="colored-fg"
      />
      <path
        d="M24 0C10.8 0 0 10.8 0 24C0 37.2 10.8 48 24 48C37.2 48 48 37.2 48 24C48 10.8 37.2 0 24 0ZM36.2 19.2C36.6 27.2 30.6 36.2 19.8 36.2C16.6 36.2 13.6 35.2 11 33.6C14 34 17 33.2 19.6 31.2C17 31.2 15 29.4 14.2 27.2C15.2 27.4 16 27.4 16.8 27.2C14 26.8 12 24.2 12.2 21.6C13 22 13.8 22.2 14.8 22.4C12.2 20.6 11.6 17.4 13 14.8C15.8 18.2 20 20.6 24.8 20.8C24 17.2 26.6 13.8 30.4 13.8C32 13.8 33.6 14.4 34.6 15.6C36 15.4 37.2 14.8 38.2 14.2C37.8 15.6 36.8 16.6 35.6 17.4C36.8 17.2 37.8 17 38.8 16.4C38.2 17.4 37.2 18.4 36.2 19.2Z"
        className="colored-bg"
      />
    </InteractiveIconSecondarySVG>
  )
}

export function MailIcon({ ...otherProps }) {
  return (
    <InteractiveIconSecondarySVG
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM11.1079 15.1612H12.1079H35.8924H36.8924V16.1612V33.3389V34.3389H35.8924H12.1079H11.1079V33.3389V16.1612V15.1612ZM13.1079 32.3389V19.548L23.4147 26.9918L24.0002 27.4147L24.5857 26.9918L34.8924 19.548V32.3389H13.1079ZM24.0002 24.9476L13.219 17.1612H34.7814L24.0002 24.9476Z"
        className="colored-bg"
      />
    </InteractiveIconSecondarySVG>
  )
}

export function FacebookIcon({ ...otherProps }) {
  return (
    <InteractiveIconSecondarySVG
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        className="colored-fg"
      />
      <path
        d="M24 0C10.8 0 0 10.8 0 24C0 37.2 10.8 48 24 48C37.2 48 48 37.2 48 24C48 10.8 37.2 0 24 0ZM30 16C30 16 28.2 16 27.4 16C26.2 16 26 16.4 26 17.6C26 18.6 26 20 26 20H30L29.6 24H26V38H20V24H16V20H20C20 20 20 18.4 20 15.4C20 11.8 21.8 10 26 10C26.6 10 30 10 30 10V16Z"
        className="colored-bg"
      />
    </InteractiveIconSecondarySVG>
  )
}

export function InstagramIcon({ ...otherProps }) {
  return (
    <InteractiveIconSecondarySVG
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill="#2E2D2D"
        className="colored-fg"
      />
      <path
        className="colored-bg"
        d="M24.6667 10.5C23.5 10.5 23 10.5 20 10.5C16.8333 10.5 16.5 10.5 15.3333 10.5C12.1667 10.6667 10.6667 12.1667 10.5 15.3333C10.5 16.5 10.5 17 10.5 20C10.5 23 10.5 23.5 10.5 24.6667C10.6667 27.8333 12.1667 29.3333 15.3333 29.5C16.5 29.5 17 29.5 20 29.5C23.1667 29.5 23.5 29.5 24.6667 29.5C27.8333 29.3333 29.3333 27.8333 29.5 24.6667C29.5 23.5 29.5 23 29.5 20C29.5 17 29.5 16.5 29.5 15.3333C29.3333 12.1667 27.8333 10.6667 24.6667 10.5ZM20 26C16.6667 26 14 23.3333 14 20C14 16.6667 16.6667 14 20 14C23.3333 14 26 16.6667 26 20C26 23.3333 23.3333 26 20 26ZM26.1667 15.1667C25.3333 15.1667 24.8333 14.5 24.8333 13.8333C24.8333 13 25.5 12.5 26.1667 12.5C27 12.5 27.5 13.1667 27.5 13.8333C27.6667 14.5 27 15.1667 26.1667 15.1667ZM23.8333 20C23.8333 22.1667 22.1667 23.8333 20 23.8333C17.8333 23.8333 16.1667 22.1667 16.1667 20C16.1667 17.8333 17.8333 16.1667 20 16.1667C22.1667 16.1667 23.8333 17.8333 23.8333 20ZM20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0ZM31.6667 24.8333C31.5 29 29.1667 31.5 24.8333 31.6667C23.6667 31.6667 23.1667 31.6667 20 31.6667C16.8333 31.6667 16.5 31.6667 15.1667 31.6667C11 31.5 8.5 29.1667 8.33333 24.8333C8.33333 23.6667 8.33333 23.1667 8.33333 20C8.33333 16.8333 8.33333 16.5 8.33333 15.1667C8.5 11 10.8333 8.5 15.1667 8.33333C16.3333 8.33333 16.8333 8.33333 20 8.33333C23.1667 8.33333 23.5 8.33333 24.8333 8.33333C29 8.5 31.5 10.8333 31.6667 15.1667C31.6667 16.3333 31.6667 16.8333 31.6667 20C31.6667 23.1667 31.6667 23.5 31.6667 24.8333Z"
        fill="#F7F3F0"
      />
    </InteractiveIconSecondarySVG>
  )
}

export function PlayIcon({ ...otherProps }) {
  return (
    <InteractiveIconPrimarySVG
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="50" cy="50" r="50" className="colored-bg" />
      <rect
        width="24"
        height="24"
        transform="translate(38 38)"
        className="colored-bg"
      />
      <path d="M42 62V38L62 50L42 62Z" className="colored-fg" />
    </InteractiveIconPrimarySVG>
  )
}

export function PauseIcon({ ...otherProps }) {
  return (
    <InteractiveIconPrimarySVG
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <circle cx="50" cy="50" r="50" className="colored-bg" />
      <path d="M35.2255 50.7769L52 40.3984L35.2255 30.02L35.2255 50.7769Z" />
      <rect
        width="24"
        height="24"
        transform="translate(38 38)"
        className="colored-bg"
      />
      <rect x="42" y="38" width="6" height="24" className="colored-fg" />
      <rect x="52" y="38" width="6" height="24" className="colored-fg" />
    </InteractiveIconPrimarySVG>
  )
}

const SVGWithColor = styled.svg`
  .colored-fg-stroke {
    stroke: ${({ theme }) => theme.textColor};
  }
  .colored-fg-fill {
    fill: ${({ theme }) => theme.textColor};
  }
  .colored-bg-stroke {
    stroke: ${({ theme }) => theme.backgroundColor};
  }
  .colored-bg-fill {
    fill: ${({ theme }) => theme.backgroundColor};
  }
`

export const CloseIcon = styled(RawCloseIcon)`
  ${interactiveIconDarkSVGStyles}
`

export const LightCloseIcon = styled(RawCloseIcon)`
  ${createInteractiveIconStyles(
    { backgroundColor: "transparent", foregroundColor: COLOR_STONE },
    { backgroundColor: "transparent", foregroundColor: COLOR_CORAL }
  )}
`

export function RawCloseIcon({ ...otherProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M23.177 3.17844L20.8203 0.821777L11.9986 9.64344L3.17698 0.821777L0.820312 3.17844L9.64198 12.0001L0.820312 20.8218L3.17698 23.1784L11.9986 14.3568L20.8203 23.1784L23.177 20.8218L14.3553 12.0001L23.177 3.17844Z"
        className="colored-fg"
      />
    </svg>
  )
}

export function OrderIcon({ ...otherProps }) {
  return (
    <SVGWithColor
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#order-icon-clip)">
        <path
          className="colored-fg-stroke"
          d="M40.0125 47.2363V59.9993C40.0125 60.7875 39.7079 61.5435 39.1657 62.1009C38.6236 62.6583 37.8882 62.9715 37.1215 62.9715H4.89095C4.12422 62.9715 3.3889 62.6583 2.84674 62.1009C2.30458 61.5435 2 60.7875 2 59.9993V4.00054C2 3.21226 2.30458 2.45627 2.84674 1.89888C3.3889 1.34148 4.12422 1.02834 4.89095 1.02834H37.1115C37.492 1.02698 37.869 1.10286 38.2209 1.25162C38.5728 1.40039 38.8927 1.6191 39.1622 1.89523C39.4317 2.17136 39.6455 2.49947 39.7914 2.86075C39.9374 3.22202 40.0125 3.60936 40.0125 4.00054V16.9281"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke colored-fg-fill"
          d="M24.3478 4.36058C24.1709 4.3565 23.9969 4.40668 23.8478 4.50474C23.6988 4.6028 23.5816 4.74428 23.5112 4.91113C23.4407 5.07798 23.4202 5.26264 23.4522 5.44152C23.4843 5.62041 23.5675 5.78542 23.6911 5.91549C23.8148 6.04557 23.9734 6.13481 24.1466 6.17183C24.3198 6.20884 24.4999 6.19196 24.6637 6.12333C24.8276 6.05469 24.9678 5.93743 25.0665 5.78649C25.1653 5.63556 25.218 5.4578 25.2181 5.2759C25.2194 5.15705 25.1979 5.03909 25.1549 4.92877C25.1119 4.81844 25.0482 4.71791 24.9673 4.63292C24.8865 4.54792 24.7902 4.48012 24.6839 4.43339C24.5776 4.38667 24.4634 4.36193 24.3478 4.36058Z"
          fill={COLOR_GRAY_800}
        />
        <path
          className="colored-fg-stroke colored-fg-fill"
          d="M20.9357 4.36058C20.7588 4.3565 20.5848 4.40668 20.4357 4.50474C20.2867 4.6028 20.1695 4.74428 20.099 4.91113C20.0286 5.07798 20.0081 5.26264 20.0401 5.44152C20.0722 5.62041 20.1554 5.78542 20.279 5.91549C20.4027 6.04557 20.5613 6.13481 20.7345 6.17183C20.9077 6.20884 21.0878 6.19196 21.2516 6.12333C21.4154 6.05469 21.5557 5.93743 21.6544 5.78649C21.7532 5.63556 21.8059 5.4578 21.806 5.2759C21.8073 5.15705 21.7858 5.03909 21.7428 4.92877C21.6998 4.81844 21.636 4.71791 21.5552 4.63292C21.4744 4.54792 21.3781 4.48012 21.2718 4.43339C21.1655 4.38667 21.0513 4.36193 20.9357 4.36058Z"
          fill={COLOR_GRAY_800}
        />
        <path
          className="colored-fg-stroke colored-fg-fill"
          d="M17.5353 4.36058C17.3584 4.3565 17.1844 4.40668 17.0353 4.50474C16.8863 4.6028 16.7691 4.74428 16.6987 4.91113C16.6282 5.07798 16.6077 5.26264 16.6397 5.44152C16.6718 5.62041 16.755 5.78542 16.8786 5.91549C17.0023 6.04557 17.1609 6.13481 17.3341 6.17183C17.5073 6.20884 17.6874 6.19196 17.8512 6.12333C18.0151 6.05469 18.1553 5.93743 18.254 5.78649C18.3528 5.63556 18.4055 5.4578 18.4056 5.2759C18.4069 5.15705 18.3854 5.03909 18.3424 4.92877C18.2994 4.81844 18.2357 4.71791 18.1548 4.63292C18.074 4.54792 17.9777 4.48012 17.8714 4.43339C17.7651 4.38667 17.6509 4.36193 17.5353 4.36058Z"
          fill={COLOR_GRAY_800}
        />
        <path
          className="colored-fg-stroke"
          d="M19.0059 31.1311V44.5934C19.0085 45.2936 19.2802 45.9643 19.7618 46.4594C20.2433 46.9545 20.8957 47.2338 21.5767 47.2365H58.4288C58.7681 47.2392 59.1045 47.1729 59.4188 47.0413C59.733 46.9097 60.0187 46.7154 60.2596 46.4697C60.5004 46.224 60.6916 45.9317 60.822 45.6097C60.9525 45.2876 61.0196 44.9423 61.0196 44.5934V19.5406C61.0196 18.8396 60.7488 18.1673 60.2667 17.6716C59.7845 17.1759 59.1306 16.8975 58.4488 16.8975H28.479"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M50.5262 37.7542C50.1998 37.2767 49.734 36.9185 49.1962 36.7316C48.6585 36.5446 48.0768 36.5387 47.5356 36.7147C46.9943 36.8906 46.5217 37.2393 46.1862 37.7101C45.8508 38.1808 45.6699 38.7493 45.6699 39.3329C45.6699 39.9165 45.8508 40.4849 46.1862 40.9557C46.5217 41.4264 46.9943 41.7751 47.5356 41.951C48.0768 42.127 48.6585 42.1211 49.1962 41.9341C49.734 41.7472 50.1998 41.389 50.5262 40.9115"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M52.6961 36.5921C52.2701 36.5922 51.8504 36.6979 51.4725 36.9002C51.0946 37.1025 50.7698 37.3954 50.5254 37.7542C50.8441 38.2154 51.0154 38.7671 51.0154 39.3329C51.0154 39.8986 50.8441 40.4503 50.5254 40.9115C50.7685 41.2716 51.0932 41.5654 51.4713 41.7679C51.8494 41.9703 52.2698 42.0753 52.6961 42.0737C53.0599 42.0966 53.4245 42.0427 53.7673 41.9153C54.1101 41.788 54.4239 41.5898 54.6894 41.333C54.9549 41.0763 55.1665 40.7664 55.3111 40.4224C55.4557 40.0784 55.5304 39.7076 55.5304 39.3329C55.5304 38.9581 55.4557 38.5873 55.3111 38.2433C55.1665 37.8993 54.9549 37.5894 54.6894 37.3327C54.4239 37.0759 54.1101 36.8778 53.7673 36.7504C53.4245 36.623 53.0599 36.5691 52.6961 36.5921V36.5921Z"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M20.9355 31.3472C25.9298 31.3472 29.9785 27.1847 29.9785 22.0501C29.9785 16.9154 25.9298 12.7529 20.9355 12.7529C15.9413 12.7529 11.8926 16.9154 11.8926 22.0501C11.8926 27.1847 15.9413 31.3472 20.9355 31.3472Z"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke colored-fg-fill"
          d="M18.7157 24.8981L15.8148 21.8128L14.8145 22.8412L18.7057 26.955L26.7084 18.4601L25.708 17.4316L18.7157 24.8981Z"
          fill="#141414"
        />
        <path
          className="colored-fg-stroke"
          d="M24.2969 36.5918H34.8103"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M18.4355 59.1357H23.1271"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          className="colored-fg-stroke"
          d="M24.2969 41.7031H42.3528"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="order-icon-clip">
          <rect width="61" height="64" fill="white" transform="translate(1)" />
        </clipPath>
      </defs>
    </SVGWithColor>
  )
}

export function DeliveryIcon({ ...otherProps }) {
  return (
    <SVGWithColor
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#delivery-icon-clip)">
        <path
          className="colored-fg-stroke"
          d="M2 34.31V7.78C2.00264 7.04351 2.29637 6.33794 2.81715 5.81715C3.33794 5.29637 4.04351 5.00264 4.78 5H33.28C34.0173 5 34.7244 5.29289 35.2458 5.81424C35.7671 6.33559 36.06 7.0427 36.06 7.78L36.26 52.64H21.26C21.192 50.8542 20.4348 49.1641 19.1473 47.9247C17.8598 46.6852 16.1421 45.9928 14.355 45.9928C12.5679 45.9928 10.8502 46.6852 9.56271 47.9247C8.27521 49.1641 7.51797 50.8542 7.45 52.64H2.2L2 34.31Z"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M7.40039 52.6396C7.46836 54.4255 8.2256 56.1156 9.5131 57.355C10.8006 58.5944 12.5182 59.2868 14.3054 59.2868C16.0925 59.2868 17.8102 58.5944 19.0977 57.355C20.3852 56.1156 21.1424 54.4255 21.2104 52.6396"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M44.1191 52.6396C44.1191 54.4696 44.8461 56.2247 46.1401 57.5187C47.4341 58.8127 49.1891 59.5396 51.0191 59.5396C52.8491 59.5396 54.6042 58.8127 55.8982 57.5187C57.1922 56.2247 57.9191 54.4696 57.9191 52.6396"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M57.1798 38.1597L49.1798 25.7497C48.7687 25.1157 48.2058 24.5943 47.5423 24.2328C46.8787 23.8714 46.1354 23.6812 45.3798 23.6797H36.2598V52.6797H44.1198C44.1198 50.8497 44.8467 49.0947 46.1407 47.8007C47.4347 46.5067 49.1898 45.7797 51.0198 45.7797C52.8498 45.7797 54.6048 46.5067 55.8988 47.8007C57.1928 49.0947 57.9198 50.8497 57.9198 52.6797H61.9998V47.7197C61.6898 44.1197 61.9998 40.9197 57.1798 38.1597Z"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke"
          d="M57.9191 52.6402C57.9191 50.8102 57.1922 49.0552 55.8982 47.7612C54.6042 46.4672 52.8491 45.7402 51.0191 45.7402C49.1891 45.7402 47.4341 46.4672 46.1401 47.7612C44.8461 49.0552 44.1191 50.8102 44.1191 52.6402"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          className="colored-fg-stroke colored-fg-fill"
          d="M51.67 37.2496H42.42C41.8322 37.2826 41.2551 37.0829 40.8134 36.6936C40.3718 36.3044 40.1011 35.7569 40.06 35.1696L40 27.8196C40.0077 27.6659 40.0456 27.5152 40.1116 27.3762C40.1776 27.2372 40.2704 27.1125 40.3847 27.0094C40.4989 26.9063 40.6324 26.8267 40.7775 26.7753C40.9225 26.7239 41.0763 26.7015 41.23 26.7096H43.38C44.1134 26.6963 44.8396 26.856 45.4998 27.1757C46.16 27.4953 46.7356 27.966 47.18 28.5496L52.33 36.2196C52.3926 36.3291 52.4241 36.4536 52.4209 36.5796C52.4178 36.7057 52.3801 36.8284 52.3121 36.9346C52.2441 37.0408 52.1482 37.1263 52.035 37.1818C51.9218 37.2373 51.7956 37.2608 51.67 37.2496Z"
        />
      </g>
      <defs>
        <clipPath id="delivery-icon-clip">
          <rect
            width="61.98"
            height="56.54"
            fill="white"
            transform="translate(1 4)"
          />
        </clipPath>
      </defs>
    </SVGWithColor>
  )
}

export function DeliveryIcon2() {
  return (
    <svg width="26" height="19" viewBox="0 0 26 19" fill="none">
      <path
        d="M0 4.08989H11.3933L12.2697 5.8427H0.876404L0 4.08989ZM1.16854 7.59551H12.5618L13.4382 9.34831H2.04494L1.16854 7.59551ZM20.1573 16.9438C21.1272 16.9438 21.9101 16.1609 21.9101 15.191C21.9101 14.2211 21.1272 13.4382 20.1573 13.4382C19.1874 13.4382 18.4045 14.2211 18.4045 15.191C18.4045 16.1609 19.1874 16.9438 20.1573 16.9438ZM21.9101 6.42697H18.9888V9.34831H24.2004L21.9101 6.42697ZM8.47191 16.9438C9.4418 16.9438 10.2247 16.1609 10.2247 15.191C10.2247 14.2211 9.4418 13.4382 8.47191 13.4382C7.50202 13.4382 6.7191 14.2211 6.7191 15.191C6.7191 16.1609 7.50202 16.9438 8.47191 16.9438ZM22.4944 4.67416L26 9.34831V15.191H23.6629C23.6629 17.1308 22.0971 18.6966 20.1573 18.6966C18.2175 18.6966 16.6517 17.1308 16.6517 15.191H11.9775C11.9775 17.1308 10.4 18.6966 8.47191 18.6966C6.53213 18.6966 4.96629 17.1308 4.96629 15.191H2.62921V11.1011H4.96629V12.8539H5.85438C6.49708 12.1411 7.43191 11.6854 8.47191 11.6854C9.51191 11.6854 10.4467 12.1411 11.0894 12.8539H16.6517V2.33708H2.62921C2.62921 1.04 3.66921 0 4.96629 0H18.9888V4.67416H22.4944Z"
        fill="#FFFEFC"
      />
    </svg>
  )
}

export function MapMarkerIcon({ ...otherProps }) {
  return (
    <SVGWithColor
      width="16"
      height="16"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M20 7.41985C20 13.5573 12 24 12 24C12 23.084 4 13.374 4 7.41985C4 3.32198 7.58172 0 12 0C16.4183 0 20 3.32198 20 7.41985Z"
        className="colored-fg-fill"
      />
      <circle cx="12" cy="8" r="4" className="colored-bg-fill" />
    </SVGWithColor>
  )
}

export function SpeechBubbleTriangleIcon({ ...otherProps }) {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M17.5618 22.7964C16.3926 24.7148 13.6074 24.7148 12.4382 22.7964L1.32523 4.5612C0.106895 2.56205 1.54586 0 3.887 0L26.113 0C28.4541 0 29.8931 2.56205 28.6748 4.5612L17.5618 22.7964Z"
        fill={COLOR_GRAY_100}
      />
    </svg>
  )
}

export function InfoCircleIcon({ ...otherProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
        fill={COLOR_GRAY_900}
      />
    </svg>
  )
}

// the blue Question Mark icon
export function HelpIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25Z"
        fill="#0395FF"
      />
    </svg>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const RotatingIcon = styled.svg.attrs({
  width: 30,
  height: 30,
  fill: "transparent",
})`
  animation: ${rotate} 0.8s linear infinite;
`

export function LoaderIcon({ dark = false, ...otherProps }) {
  const primaryColor = dark ? COLOR_DARK_CORAL : COLOR_STONE
  const secondaryColor = dark ? COLOR_DARK_CORAL : COLOR_GRAY_100

  return (
    <RotatingIcon {...otherProps}>
      <g>
        <path
          id="svg_3"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="4"
          stroke={primaryColor}
          d="m27.48469,15.04318c0,6.9034 -5.596,12.5 -12.5,12.5c-6.9042,0 -12.5,-5.5966 -12.5,-12.5c0,-6.9034 5.5962,-12.5 12.5,-12.5"
        />
        <circle
          id="svg_4"
          strokeWidth="4"
          strokeOpacity="0.5"
          stroke={secondaryColor}
          r="12.5"
          cy="15.04318"
          cx="14.98469"
        />
      </g>
    </RotatingIcon>
  )
}

// green checkmark icon
export function SuccessIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#02A40B" />
      <path
        d="M17.5361 26.378L11.8311 21.243L13.1691 19.757L17.4641 23.622L26.7931 14.293L28.2071 15.707L17.5361 26.378Z"
        fill="white"
      />
    </svg>
  )
}

export function OnlineFormIcon({ ...otherProps }) {
  return (
    <svg
      width="51"
      height="55"
      viewBox="0 0 51 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M30.1278 51.5508H4.15849C2.62542 51.5508 1.37109 50.2472 1.37109 48.6501V9.72557V3.9007C1.37109 2.30355 2.62542 1 4.15849 1H47.1252C48.6582 1 49.9067 2.30355 49.9067 3.9007V9.7197V48.6501C49.9067 50.2414 48.6524 51.5508 47.1252 51.5508H35.4645"
        stroke={COLOR_STONE}
        strokeWidth="1.16142"
        strokeMiterlimit="10"
      />
      <path
        d="M49.5357 7.21289H1"
        stroke={COLOR_STONE}
        strokeWidth="1.16142"
        strokeMiterlimit="10"
      />
      <path
        d="M43.3579 16.9706H7.19716L7.18555 16.9588V13.3008L7.19716 13.2891H43.3521V13.3008V16.9588L43.3579 16.9706Z"
        stroke={COLOR_STONE}
        strokeWidth="1.16142"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.3579 24.4766H7.19716L7.18555 24.4648V20.8067L7.19716 20.7949H43.3521V20.8067V24.4648L43.3579 24.4766Z"
        stroke={COLOR_STONE}
        strokeWidth="1.16142"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.3579 36.5713H7.19716L7.18555 36.5537V28.3213L7.19716 28.3037H43.3521V28.3213V36.5537L43.3579 36.5713Z"
        stroke={COLOR_STONE}
        strokeWidth="1.16142"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3179 3.6377C11.9289 3.6377 11.6211 3.96646 11.6211 4.35394C11.6211 4.74142 11.9289 5.07019 12.3179 5.07019C12.707 5.07019 13.0148 4.74142 13.0148 4.35394C13.0148 3.96646 12.7128 3.6377 12.3179 3.6377Z"
        fill={COLOR_STONE}
      />
      <path
        d="M9.46051 3.6377C9.07144 3.6377 8.76367 3.96646 8.76367 4.35394C8.76367 4.74142 9.06563 5.07019 9.46051 5.07019C9.85539 5.07019 10.1573 4.74142 10.1573 4.35394C10.1573 3.96646 9.84958 3.6377 9.46051 3.6377Z"
        fill={COLOR_STONE}
      />
      <path
        d="M6.32381 3.6377C5.93474 3.6377 5.62695 3.96646 5.62695 4.35394C5.62695 4.74142 5.92893 5.07019 6.32381 5.07019C6.71289 5.07019 7.02066 4.74142 7.02066 4.35394C7.02066 3.96646 6.71289 3.6377 6.32381 3.6377Z"
        fill={COLOR_STONE}
      />
      <path
        d="M29.1933 43.3703C29.4197 43.2294 29.6172 43.3116 29.8204 43.429C31.2083 44.2394 32.5962 45.0497 33.9899 45.86C34.7158 45.5606 35.2326 44.8266 35.2326 43.9634C35.2326 42.8359 34.3558 41.9258 33.2756 41.9258H18.9902C17.9101 41.9258 17.0332 42.8418 17.0332 43.9634C17.0332 45.0908 17.9101 46.001 18.9902 46.001H29.141C29.0771 45.3374 29.019 44.6739 28.9552 44.0045C28.9261 43.7579 28.9552 43.523 29.1933 43.3703Z"
        fill={COLOR_STONE}
      />
      <path
        d="M36.9569 47.5945C35.9639 47.019 34.9767 46.4377 33.9837 45.8623C33.763 45.9563 33.5249 46.0032 33.2694 46.0032H32.2764C33.4553 46.6902 34.6341 47.3714 35.8304 48.0701C35.2845 48.4342 34.7909 48.7571 34.3089 49.0801C33.8211 49.4089 33.7746 49.6379 34.094 50.137C34.6109 50.9473 35.1277 51.7635 35.6445 52.562C35.3077 52.7852 34.9999 52.9965 34.6689 53.2138C34.1869 52.4446 33.6875 51.6519 33.1939 50.8533C32.8048 50.2309 32.6713 50.2074 32.0673 50.6185C31.6144 50.9297 31.1556 51.2467 30.6446 51.5991C30.4704 49.7201 30.302 47.8763 30.122 45.9974H29.1348C29.338 48.1582 29.5354 50.3249 29.7445 52.4857C29.8026 53.114 30.1568 53.2843 30.662 52.9378C31.2718 52.5268 31.8815 52.1158 32.4913 51.6989C33.0313 52.562 33.5714 53.4193 34.1173 54.2766C34.3379 54.6289 34.6051 54.6817 34.9419 54.4586C35.4819 54.0887 36.0278 53.7246 36.5678 53.3488C36.9279 53.1022 36.9744 52.8556 36.7363 52.4681C36.2949 51.7576 35.8478 51.0412 35.3948 50.3366C35.3019 50.1957 35.3948 50.3366 35.087 49.8845C35.8304 49.3913 36.3762 49.0331 36.9802 48.6162C37.1021 48.534 37.2299 48.3989 37.2821 48.2639C37.3867 47.9527 37.2299 47.753 36.9569 47.5945Z"
        fill={COLOR_STONE}
      />
      <path
        d="M30.0015 44.6864C30.768 45.1327 31.523 45.573 32.2779 46.0134H33.2709C33.5206 46.0134 33.7587 45.9606 33.9852 45.8725C32.5973 45.0622 31.2036 44.2519 29.8157 43.4416C29.6124 43.3242 29.4092 43.242 29.1885 43.3829C28.9504 43.5356 28.9272 43.7704 28.9504 44.0229C29.0143 44.6864 29.0724 45.3499 29.1362 46.0193H30.1234C30.0828 45.5672 30.0421 45.1327 30.0015 44.6864Z"
        fill={COLOR_STONE}
      />
    </svg>
  )
}

export function CalendarAppointmentIcon({ ...otherProps }) {
  return (
    <svg
      width="50"
      height="56"
      viewBox="0 0 50 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M45.612 54.4592H4.38797C2.52746 54.4592 1 52.8741 1 50.9348V8.06059C1 6.12127 2.52171 4.53613 4.38797 4.53613H45.612C47.4725 4.53613 49 6.12127 49 8.06059V50.9406C48.9943 52.8741 47.4725 54.4592 45.612 54.4592Z"
        stroke={COLOR_STONE}
        strokeWidth="1.14846"
        strokeMiterlimit="10"
      />
      <path
        d="M1 17.4141H48.9943"
        stroke={COLOR_STONE}
        strokeWidth="1.14846"
        strokeMiterlimit="10"
      />
      <path
        d="M11.2559 1V8.07202"
        stroke={COLOR_STONE}
        strokeWidth="1.14846"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.9961 1V8.07202"
        stroke={COLOR_STONE}
        strokeWidth="1.14846"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.709 1V8.07202"
        stroke={COLOR_STONE}
        strokeWidth="1.14846"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.7352 40.2346L15.1939 34.4689L13.3047 36.4198L20.7352 44.1481L36.6874 27.5593L34.8154 25.6084L20.7352 40.2346Z"
        fill={COLOR_STONE}
      />
    </svg>
  )
}

export function LocateNearbyIcon({ ...otherProps }) {
  return (
    <SVGWithColor
      width="48"
      height="65"
      viewBox="0 0 48 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#locate-nearby-clip-path)">
        <path
          d="M37.6865 6.7361H44.0393C45.8506 6.7361 47.3314 8.54081 47.3314 10.7488V59.5619C47.3314 61.7698 45.8506 63.5746 44.0393 63.5746H3.95226C2.14094 63.5679 0.660156 61.7632 0.660156 59.5619V10.7422C0.660156 8.5342 2.14094 6.72949 3.95226 6.72949H27.6184"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
        />
        <path
          d="M32.6382 0C28.8172 0 25.7168 2.87564 25.7168 6.41895C25.7168 11.5687 32.6382 19.9708 32.6382 20.7641C32.6382 20.7641 39.5595 11.7339 39.5595 6.41895C39.5595 2.87564 36.4591 0 32.6382 0ZM32.6382 10.3787C30.7277 10.3787 29.1808 8.83184 29.1808 6.92136C29.1808 5.01088 30.7277 3.46399 32.6382 3.46399C34.5486 3.46399 36.0955 5.01088 36.0955 6.92136C36.0955 8.83184 34.5486 10.3787 32.6382 10.3787Z"
          fill={COLOR_STONE}
          className="colored-fg-fill"
        />
        <path
          d="M37.2516 10.377H41.0329C42.5732 10.377 43.8292 11.785 43.8292 13.5104V51.6605C43.8292 53.3859 42.5732 54.794 41.0329 54.794H6.96818C5.4279 54.794 4.17188 53.3859 4.17188 51.6605V13.5104C4.17188 11.785 5.4279 10.377 6.96818 10.377H27.1902"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
        />
        <path
          d="M23.9961 61.0349C24.9454 61.0349 25.7149 60.2506 25.7149 59.2831C25.7149 58.3156 24.9454 57.5312 23.9961 57.5312C23.0469 57.5312 22.2773 58.3156 22.2773 59.2831C22.2773 60.2506 23.0469 61.0349 23.9961 61.0349Z"
          fill={COLOR_STONE}
          className="colored-fg-fill"
        />
        <path
          d="M4.74609 38.3617C12.8904 31.5659 21.0348 24.7701 29.1791 17.9678"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M18.9141 54.4437C27.0584 47.6479 35.2027 40.8522 43.3471 34.0498"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M25.14 54.444C18.3376 46.2996 11.5419 38.1619 4.74609 30.0176"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M43.8272 49.6538C43.5893 49.1382 43.3182 48.6556 43.0273 48.2061"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
        />
        <path
          d="M40.8595 45.7385C36.1659 41.7324 28.914 41.7522 25.7144 41.0317C18.3964 39.3724 10.6884 34.0508 12.2617 20.9551C12.6187 17.9869 11.9378 15.3757 10.8008 13.1611"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
          strokeDasharray="3.29 3.29"
        />
        <path
          d="M9.98123 11.7333C9.67714 11.264 9.35983 10.8078 9.0293 10.3848"
          stroke={COLOR_STONE}
          className="colored-fg-stroke"
          strokeWidth="1.32213"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="locate-nearby-clip-path">
          <rect width="48" height="64.2292" fill="white" />
        </clipPath>
      </defs>
    </SVGWithColor>
  )
}

export function PickUpAvailableIcon({ ...otherProps }) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path d="M7 8.00195L7 10.002" stroke={COLOR_GRAY_400} />
      <path
        d="M4.5 12.502C4.5 12.2258 4.27614 12.002 4 12.002C3.72386 12.002 3.5 12.2258 3.5 12.502H4.5ZM8.5 12.502C8.5 13.6065 7.60457 14.502 6.5 14.502V15.502C8.15685 15.502 9.5 14.1588 9.5 12.502H8.5ZM6.5 14.502C5.39543 14.502 4.5 13.6065 4.5 12.502H3.5C3.5 14.1588 4.84315 15.502 6.5 15.502V14.502ZM6.5 10.502C7.60457 10.502 8.5 11.3974 8.5 12.502H9.5C9.5 10.8451 8.15685 9.50195 6.5 9.50195V10.502Z"
        fill={COLOR_GRAY_400}
      />
      <path d="M7.99512 1L5.05478 8.026" stroke={COLOR_GRAY_400} />
      <path d="M10.4971 1.00293L7.55674 8.02893" stroke={COLOR_GRAY_400} />
      <path
        d="M3.96429 6.18752L3.77872 6.65181L2.85014 6.28067L3.03571 5.81638L3.96429 6.18752ZM5.96234 1.1885L3.96429 6.18752L3.03571 5.81638L5.03376 0.817359L5.96234 1.1885Z"
        fill={COLOR_GRAY_400}
      />
      <path
        d="M1.54113 2.36982C1.28148 1.71347 1.76517 1.00195 2.47101 1.00195H11.529C12.2348 1.00195 12.7185 1.71347 12.4589 2.36982L10.4808 7.36982C10.3299 7.75134 9.96125 8.00195 9.55097 8.00195H4.44904C4.03875 8.00195 3.67009 7.75134 3.51916 7.36982L1.54113 2.36982Z"
        stroke={COLOR_GRAY_400}
      />
    </svg>
  )
}

export function UnuClassicScooterIcon({ ...otherProps }) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M10.7416 15.233H2.98812C2.98812 15.233 4.84122 11.3741 7.67785 10.8284C7.67785 10.8284 10.0608 11.506 13.0108 12.9742C13.0108 12.9742 10.8741 14.1977 10.7416 15.233ZM10.7416 15.233L12.8269 18.5959C12.9354 18.7705 13.087 18.9142 13.2672 19.0132C13.4473 19.1122 13.6499 19.1632 13.8554 19.1612L15.4316 19.148C20.0648 18.8846 19.9324 17.9999 21.3318 15.327L22.2373 13.2239C22.3862 12.8787 22.4193 12.4945 22.3317 12.1289L21.94 10.4912C21.9131 10.3782 21.8638 10.2716 21.795 10.1779L20.0269 7.76017M10.7416 15.233L8.45346 16.5881M20.0269 7.76017L18.2304 8.90857C18.8166 9.6804 19.6109 11.2426 19.5731 12.6921C19.5354 14.1412 18.6844 16.5694 18.6844 16.5694L14.6373 16.2306C13.6919 15.7036 14.2213 14.4425 14.2213 14.4425L14.8684 12.2349M20.0269 7.76017L17.6441 7.68506M7.67814 10.8284V10.115C7.67814 9.91197 7.85345 9.75319 8.0563 9.77233L13.8997 10.3077C14.0666 10.3228 14.2266 10.3819 14.3634 10.4788C14.5001 10.5759 14.6087 10.7075 14.6782 10.8601C14.8307 11.1974 15.0138 11.6104 15.0744 11.7816C15.1054 11.8691 15.1013 11.9653 15.0629 12.0498C15.0244 12.1343 14.9547 12.2007 14.8684 12.2349M14.8684 12.2349L13.011 12.9744M12.2356 17.7176L8.983 18.4704M22.3909 12.7673C22.3909 12.7673 25.3977 12.8237 25.8894 15.1953H21.3886L22.3909 12.7673ZM21.9147 10.3642C21.9147 10.3642 23.4119 9.17232 22.0124 7.96752L20.0268 7.7606L21.9147 10.3642ZM2.90039 18.3104C2.90039 19.9838 4.26339 21.3403 5.94438 21.3403C7.62565 21.3403 8.98851 19.9838 8.98851 18.3104C8.98851 16.6371 7.62551 15.2806 5.94438 15.2806C4.26325 15.2806 2.90039 16.6371 2.90039 18.3104ZM20.1808 18.3104C20.1808 19.9838 21.5438 21.3403 23.2249 21.3403C24.9062 21.3403 26.269 19.9838 26.269 18.3104C26.269 16.6371 24.9062 15.2806 23.2249 15.2806C21.5438 15.2806 20.1808 16.6371 20.1808 18.3104Z"
        stroke={COLOR_GRAY_400}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function ClockIcon({ ...otherProps }) {
  return (
    <SVGWithColor
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M12.8333 4C14.4155 4 15.9622 4.46919 17.2778 5.34824C18.5934 6.22729 19.6188 7.47672 20.2243 8.93853C20.8298 10.4003 20.9882 12.0089 20.6795 13.5607C20.3709 15.1126 19.6089 16.538 18.4901 17.6569C17.3713 18.7757 15.9458 19.5376 14.394 19.8463C12.8421 20.155 11.2336 19.9965 9.77179 19.391C8.30998 18.7855 7.06055 17.7602 6.1815 16.4446C5.30245 15.129 4.83326 13.5823 4.83326 12C4.83564 9.879 5.67926 7.84555 7.17903 6.34578C8.67881 4.846 10.7123 4.00238 12.8333 4V4ZM12.8333 2C10.8554 2 8.92205 2.58649 7.27755 3.6853C5.63306 4.78412 4.35134 6.3459 3.59446 8.17317C2.83758 10.0004 2.63955 12.0111 3.0254 13.9509C3.41126 15.8907 4.36366 17.6725 5.76219 19.0711C7.16071 20.4696 8.94254 21.422 10.8824 21.8079C12.8222 22.1937 14.8328 21.9957 16.6601 21.2388C18.4874 20.4819 20.0491 19.2002 21.148 17.5557C22.2468 15.9112 22.8333 13.9778 22.8333 12C22.8333 10.6868 22.5746 9.38642 22.0721 8.17317C21.5695 6.95991 20.8329 5.85752 19.9043 4.92893C18.9757 4.00035 17.8733 3.26375 16.6601 2.7612C15.4468 2.25866 14.1465 2 12.8333 2V2ZM17.9863 14.706L13.8333 12.064V6H11.8333V13.16L16.9123 16.392L17.9863 14.706Z"
        className="colored-fg-fill"
      />
    </SVGWithColor>
  )
}

export function CalendarIcon({ ...otherProps }) {
  return (
    <SVGWithColor
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M4 4H20V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V4Z"
        className="colored-fg-stroke"
        stroke-width="2"
      />
      <rect x="4" y="7" width="16" height="2" className="colored-fg-fill" />
      <rect x="7" y="11" width="2" height="2" className="colored-fg-fill" />
      <rect x="11" y="11" width="2" height="2" className="colored-fg-fill" />
      <rect x="15" y="11" width="2" height="2" className="colored-fg-fill" />
      <rect x="7" y="15" width="2" height="2" className="colored-fg-fill" />
      <rect x="11" y="15" width="2" height="2" className="colored-fg-fill" />
    </SVGWithColor>
  )
}

const SSVGWithColor = styled.svg`
  path {
    transition: fill ${ANIMATION_DURATION}ms ${ANIMATION_EASING},
      stroke ${ANIMATION_DURATION}ms ${ANIMATION_EASING};
  }
`

export function Cube3DIcon({ ...otherProps }) {
  return (
    <SSVGWithColor
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        d="M17.447 4.10572L9.447 0.105716C9.30807 0.0361955 9.15485 0 8.9995 0C8.84415 0 8.69093 0.0361955 8.552 0.105716L0.552001 4.10572C0.385954 4.18892 0.246361 4.31673 0.148864 4.4748C0.0513679 4.63288 -0.000178984 4.81499 4.66948e-07 5.00072V15.0007C-8.61286e-05 15.1865 0.0516103 15.3687 0.14929 15.5268C0.246969 15.6849 0.386768 15.8126 0.553 15.8957L8.553 19.8957C8.69193 19.9652 8.84515 20.0014 9.0005 20.0014C9.15585 20.0014 9.30907 19.9652 9.448 19.8957L17.448 15.8957C17.614 15.8125 17.7536 15.6847 17.8511 15.5266C17.9486 15.3686 18.0002 15.1864 18 15.0007V5.00072C18.0001 4.81488 17.9484 4.6327 17.8507 4.47462C17.753 4.31653 17.6132 4.18878 17.447 4.10572ZM9 2.11872L14.764 5.00072L9 7.88272L3.236 5.00072L9 2.11872ZM2 6.61872L8 9.61872V17.3827L2 14.3827V6.61872ZM10 17.3827V9.61872L16 6.61872V14.3827L10 17.3827Z"
        className="colored-fg-fill"
      />
    </SSVGWithColor>
  )
}
