import React, { forwardRef } from "react"
import styled from "styled-components"
import { LoadingComponentDecorator } from "../LoadingComponentDecorator"
import { HostedVideo } from "./HostedVideo"
import { YouTubeVideo } from "./YouTubeVideo"

export const Video = forwardRef(
  ({ videoEntry, autoPlay: autoPlayOverride, ...otherProps }, ref) => {
    const autoPlay =
      typeof autoPlayOverride === "boolean"
        ? autoPlayOverride
        : videoEntry.autoPlay

    return videoEntry.assetMp4 ? (
      <HostedVideo
        autoPlay={autoPlay}
        videoEntry={videoEntry}
        {...otherProps}
      />
    ) : videoEntry.youtubeUrl ? (
      <YouTubeVideo
        autoPlay={autoPlay}
        videoEntry={videoEntry}
        {...otherProps}
      />
    ) : null
  }
)

export function LoadingDecoratedVideo({
  avoidColors,
  colorShift,
  className,
  ...otherProps
}) {
  return (
    <LoadingComponentDecorator
      avoidColors={avoidColors}
      colorShift={colorShift}
      className={className}
    >
      {({ onLoad, onError }) => (
        <SLoadingDecoratedVideo
          onLoad={onLoad}
          onError={onError}
          {...otherProps}
        />
      )}
    </LoadingComponentDecorator>
  )
}

const SLoadingDecoratedVideo = styled(Video)`
  display: block;
  height: 100%;
`
