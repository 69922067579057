import React, { forwardRef, useCallback, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import get from "lodash/get"
import {
  COLOR_TEXT_DISABLED,
  COLOR_TEXT_INVERTED,
  COLOR_TEXT_PRIMARY,
  getThemeByName,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
} from "../../styles"
import {
  COLOR_DARK_CORAL,
  COLOR_ERROR,
  COLOR_GRAY_100,
  COLOR_GRAY_300,
  COLOR_GRAY_900,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XS,
} from "../../styles/primitives"
import { createTextStylesFromToken, Text } from "../Text"
import { ErrorMessage } from "./ErrorMessage"

const inputPositioningStyles = css`
  flex: 1;
  height: 40px;
  margin-left: ${SPACE_L - SPACE_S}px;
  margin-right: ${SPACE_S}px;
`

export const inputFontStyles = createTextStylesFromToken({
  SM: TEXT_TOKEN_COPY_BREAKPOINT_SM,
  MD: TEXT_TOKEN_COPY_BREAKPOINT_SM,
  LG: TEXT_TOKEN_COPY_BREAKPOINT_SM,
  XL: TEXT_TOKEN_COPY_BREAKPOINT_SM,
})

const StyledInput = styled.input`
  display: block;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  background-color: transparent;
  ${inputPositioningStyles};
  ${inputFontStyles};
`

const LeftAppendContainer = styled.div`
  overflow: hidden;
`

const RightAppendContainer = styled.div``

const InputContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ rounded, variantConfig, isFocused, small }) => {
    return css`
      padding: ${small
        ? css`0 ${SPACE_S}px`
        : css`
            ${SPACE_S}px
          `};

      border-radius: ${rounded ? 100 : 8}px;
      ${isFocused
        ? createInputStyle(variantConfig.active)
        : createInputStyle(variantConfig.default)};

      &:hover {
        ${createInputStyle(variantConfig.active)};
      }
    `
  }};
`

function createInputStyle(stateStyle) {
  return css`
    border: ${stateStyle.borderWidth}px solid ${stateStyle.borderColor};
    background-color: ${stateStyle.backgroundColor};

    ${StyledInput} {
      color: ${stateStyle.textColor};

      &::placeholder {
        color: ${stateStyle.placeholderColor};
      }
    }

    ${StyledInput}:-webkit-autofill {
      color: ${stateStyle.textColor};
      -webkit-box-shadow: 0 0 0px 1000px ${stateStyle.backgroundColor} inset;
      background-color: transparent;
    }
  `
}

export const Input = forwardRef(
  (
    {
      type,
      placeholder,
      leftAppend,
      rightAppend,
      onChange,
      onFocus,
      onBlur,
      name,
      disabled,
      inputMode,
      variant = "light",
      rounded = false,
      required = false,
      error,
      errors = {},
      isSubmitting,
      small,
      ...otherProps
    },
    ref
  ) => {
    const {
      variantConfig,
      isFocused,
      handleFocus,
      handleBlur,
      shownError,
    } = useInputStyle({
      errors,
      error,
      name,
      variant,
      onFocus,
      onBlur,
      disabled,
    })

    return (
      <Root {...otherProps}>
        <InputContainer
          variantConfig={variantConfig}
          rounded={rounded}
          showError={!!shownError}
          isFocused={isFocused}
          small={small}
        >
          <LeftAppendContainer>{leftAppend}</LeftAppendContainer>

          <StyledInput
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name={name}
            ref={ref}
            theme={getThemeByName("stone")}
            disabled={disabled}
            required={required}
            inputMode={inputMode}
          />

          <RightAppendContainer>{rightAppend}</RightAppendContainer>
        </InputContainer>
        <ErrorMessageText type="meta" alignWithInputValue={rounded}>
          {shownError ? <ErrorMessage error={shownError} /> : " "}
        </ErrorMessageText>
      </Root>
    )
  }
)

export function useInputStyle({
  errors,
  error,
  name,
  variant,
  onFocus,
  onBlur,
  disabled,
}) {
  const shownError = error ? error : get(errors, name)

  const variantConfig = useMemo(() => {
    const overrideStyles = shownError
      ? inputStyles[variant].error
      : disabled
      ? inputStyles[variant].disabled
      : {}

    return {
      ...inputStyles[variant],
      default: {
        ...inputStyles[variant].base,
        ...overrideStyles,
      },
      active: {
        ...inputStyles[variant].base,
        ...overrideStyles,
        ...inputStyles[variant].active,
      },
    }
  }, [variant, shownError, disabled])

  const [isFocused, setIsFocused] = useState(false)
  const handleFocus = useCallback(
    event => {
      onFocus?.(event)
      setIsFocused(true)
    },
    [onFocus]
  )
  const handleBlur = useCallback(
    event => {
      onBlur?.(event)
      setIsFocused(false)
    },
    [onBlur]
  )

  return { variantConfig, handleBlur, handleFocus, isFocused, shownError }
}
export const inputStyles = {
  light: {
    base: {
      placeholderColor: COLOR_TEXT_DISABLED,
      textColor: COLOR_TEXT_PRIMARY,
      borderColor: COLOR_GRAY_300,
      backgroundColor: COLOR_GRAY_100,
      borderWidth: 1,
    },
    active: {
      borderColor: COLOR_DARK_CORAL,
    },
    error: {
      textColor: COLOR_ERROR,
    },
    disabled: {
      textColor: COLOR_TEXT_DISABLED,
    },
  },
  dark: {
    base: {
      placeholderColor: COLOR_TEXT_DISABLED,
      textColor: COLOR_TEXT_INVERTED,
      borderColor: COLOR_GRAY_900,
      backgroundColor: COLOR_GRAY_900,
      borderWidth: 1,
    },
    active: {
      borderColor: COLOR_DARK_CORAL,
    },
    error: {
      textColor: COLOR_ERROR,
    },
    disabled: {
      textColor: COLOR_TEXT_DISABLED,
    },
  },
}

const Root = styled.div`
  & ~ & {
    margin-top: ${SPACE_S}px;
  }
`

const ErrorMessageText = styled(Text)`
  display: block;
  margin-top: ${SPACE_XS}px;
  white-space: pre;

  ${({ alignWithInputValue }) =>
    alignWithInputValue &&
    css`
      margin-left: ${SPACE_S + SPACE_M}px;
    `}

  color: ${({ theme }) =>
    ({
      coral: COLOR_GRAY_100,
      green: COLOR_GRAY_100,
      dark: COLOR_ERROR,
      stone: COLOR_ERROR,
    }[theme.themeName])};
`
