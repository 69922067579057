import React, { useMemo, useState } from "react"
import { graphql, Link as GLink } from "gatsby"
import styled, { css } from "styled-components"
import { breakpoints, COLOR_TEXT_INVERTED, ThemeProvider } from "../styles"
import {
  SPACE_1XL,
  SPACE_XL,
  SPACE_2XL,
  SPACE_L,
  COLOR_DARK_CORAL,
  SPACE_M,
  COLOR_GRAY_700,
  SPACE_S,
  SPACE_XS,
  COLOR_GRAY_300,
} from "../styles/primitives"
import { CTAButton, resolveCTALink } from "./CTAButton"
import UnuLogo from "../images/logo-unu-by-emco.svg"
import PaypalLogo from "../images/paypal-logo.svg"
import VisaLogo from "../images/visa-logo.svg"
import MastercardLogo from "../images/mastercard-logo.svg"
import AmexLogo from "../images/amex-logo.svg"
import InstallmentLogo from "../images/installment-logo.svg"
import iDealLogo from "../images/iDeal-logo.svg"
import FacebookLogo from "../images/facebook-logo.svg"
import YoutubeLogo from "../images/youtube-logo.svg"
import TwitterLogo from "../images/twitter-logo.svg"
import LinkedinLogo from "../images/linkedin-logo.svg"
import InstagramLogo from "../images/instagram-logo.svg"
import { MinusIcon, PlusIcon } from "../icons/icons"
import { CopyStrongText, Text } from "./Text"
import { resolveLink } from "../utils/linkResolver"
import { useLocale } from "../locale"
import { Link } from "./links"
import { createLinkStyles } from "./links/linkStyles"
import { ChangeLocaleButton } from "./ChangeLocaleButton"
import { MAX_WIDTH } from "../styles/breakpoints"
import { IubendaPolicyLink } from "./IubendaPolicyLink"
import { Fragment } from "react"
import Trustbadge from "./shop/Trustbadge"
import GPEn from "../images/google-play-badge-en.png"
import GPDe from "../images/google-play-badge-de.png"
import GPFr from "../images/google-play-badge-fr.png"
import GPNl from "../images/google-play-badge-nl.png"
import ASEn from "../images/app-store-badge-en.svg"
import ASDe from "../images/app-store-badge-de.svg"
import ASFr from "../images/app-store-badge-fr.svg"
import ASNl from "../images/app-store-badge-nl.svg"

const Footer = ({
  slugPrefix,
  country,
  language,
  localizedNodes,
  mainData,
}) => {
  const {
    mainLinkHomepagelogo,
    footerSocialMediaHeadline,
    footerPaymentOptionsHeadline,
    footerSecureShoppingHeadline,
    footerProductNavigationLinksHeadline,
    footerProductNavigationLinks,
    footerCompanyNavigationLinksHeadline,
    footerCompanyNavigationLinks,
    footerNewsletterHeadline,
    footerNewsletterCta,
    footerAppStoresLinksHeadline,
    footerUnuCopyrightText,
    footerBottomNavigationLinks,
    footerCookiePolicyLinkLabel,
    footerPrivacyPolicyLinkLabel,
    cookiePolicyIubendaLink,
    privacyPolicyIubendaLink,
  } = mainData
  const [productExpanded, setProductExpanded] = useState(false)
  const [aboutUsExpanded, setAboutUsExpanded] = useState(false)
  const [paymentOptionsExpanded, setPaymentOptionsExpanded] = useState(false)
  const [secureShoppingExpanded, setSecureShoppingExpanded] = useState(false)

  function toggleProductExpanded() {
    setProductExpanded(!productExpanded)
  }
  function toggleAbboutUsExpanded() {
    setAboutUsExpanded(!aboutUsExpanded)
  }
  function togglePaymentOptionsExpanded() {
    setPaymentOptionsExpanded(!paymentOptionsExpanded)
  }
  function toggleSecureShoppingExpanded() {
    setSecureShoppingExpanded(!secureShoppingExpanded)
  }

  const productLinks = useLinks(footerProductNavigationLinks)
  const productLinksElements = useLinkElements(productLinks, NavigationLinkText)
  const companyLinks = useLinks(footerCompanyNavigationLinks)
  const companyLinkElements = useLinkElements(companyLinks, NavigationLinkText)
  const cmsBottomLinks = useLinks(footerBottomNavigationLinks)
  const allBottomLinks = useMemo(() => [...cmsBottomLinks], [cmsBottomLinks])
  const bottomLinkElements = useLinkElements(
    allBottomLinks,
    BottomNavigationLinkText
  )

  return (
    <ThemeProvider themeName="dark">
      <FooterContainer>
        <FooterLogo>
          <Link
            to={mainLinkHomepagelogo}
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <img src={UnuLogo} alt="Unu" />
          </Link>
        </FooterLogo>

        <SChangeLocaleButton
          localizedNodes={localizedNodes}
          mainData={mainData}
        />

        <ProductLinks>
          <ExpandableSectionHeadlineText onClick={toggleProductExpanded}>
            {footerProductNavigationLinksHeadline}
            <ToggleIcon isExpanded={productExpanded} />
          </ExpandableSectionHeadlineText>
          <ExpandableBlock show={productExpanded}>
            {productLinksElements}
          </ExpandableBlock>
        </ProductLinks>

        <AboutUsLinks>
          <ExpandableSectionHeadlineText onClick={toggleAbboutUsExpanded}>
            {footerCompanyNavigationLinksHeadline}
            <ToggleIcon isExpanded={aboutUsExpanded} />
          </ExpandableSectionHeadlineText>
          <ExpandableBlock show={aboutUsExpanded}>
            {companyLinkElements}
          </ExpandableBlock>
        </AboutUsLinks>

        <PaymentOptions>
          <ExpandableSectionHeadlineText onClick={togglePaymentOptionsExpanded}>
            {footerPaymentOptionsHeadline}
            <ToggleIcon isExpanded={paymentOptionsExpanded} />
          </ExpandableSectionHeadlineText>

          <ExpandableBlock show={paymentOptionsExpanded} horizontalLinks={true}>
            <PaymentOption>
              <img src={PaypalLogo} alt="Paypal" />
            </PaymentOption>
            <PaymentOption>
              <img src={VisaLogo} alt="Visa" />
            </PaymentOption>
            <PaymentOption>
              <img src={MastercardLogo} alt="Mastercard" />
            </PaymentOption>
            <PaymentOption>
              <img src={AmexLogo} alt="Amex" />
            </PaymentOption>
            <PaymentOption>
              <img src={InstallmentLogo} alt="Installment" />
            </PaymentOption>
            <PaymentOption>
              <img src={iDealLogo} alt="iDeal" />
            </PaymentOption>
          </ExpandableBlock>
        </PaymentOptions>

        <SecureShopping>
          <ExpandableSectionHeadlineText onClick={toggleSecureShoppingExpanded}>
            {footerSecureShoppingHeadline}
            <ToggleIcon isExpanded={secureShoppingExpanded} />
          </ExpandableSectionHeadlineText>
          <ExpandableBlock show={secureShoppingExpanded}>
            <Trustbadge width={200} />
          </ExpandableBlock>
        </SecureShopping>

        <NewsletterSection>
          <SectionHeadlineText>{footerNewsletterHeadline}</SectionHeadlineText>
          <CTAButton
            cta={footerNewsletterCta}
            style={{ width: "auto", minWidth: 0 }}
          />
        </NewsletterSection>

        <AppStoreSection>
          <SectionHeadlineText>
            {footerAppStoresLinksHeadline}
          </SectionHeadlineText>
          <a href="https://apps.apple.com/app/unu/id1578445339">
            <img
              alt="App Store"
              src={appStoreBadge(language)}
              style={{ width: "135px" }}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.unumotors.app&pli=1">
            <img
              alt="Google Play"
              src={googlePlayBadge(language)}
              style={{
                width: language === "en" ? "157px" : "135px",
                marginLeft: language === "en" ? "-11px" : "0",
              }}
            />
          </a>
        </AppStoreSection>

        <SocialMediaSection>
          <SectionHeadlineText>{footerSocialMediaHeadline}</SectionHeadlineText>
          <div>
            <StyledLink
              to="https://www.facebook.com/unumotors/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img src={FacebookLogo} alt="Facebook" />
            </StyledLink>
            <StyledLink
              to="https://www.youtube.com/channel/UCbvJ2Mc5SjJShXyTUUYNpBA"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img src={YoutubeLogo} alt="Youtube" />
            </StyledLink>
            <StyledLink
              to="https://www.linkedin.com/company/unu-ug"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img src={LinkedinLogo} alt="Linkedin" />
            </StyledLink>

            <StyledLink
              to="https://www.instagram.com/unu/"
              rel="noreferrer noopener"
              target="_blank"
            >
              <img src={InstagramLogo} alt="Instagram" />
            </StyledLink>
          </div>
        </SocialMediaSection>

        <BottomRow>
          <BottomRowContent>
            <CopyrightText type="meta">
              © {new Date().getFullYear()} {footerUnuCopyrightText}
            </CopyrightText>
            {bottomLinkElements}
          </BottomRowContent>
        </BottomRow>
      </FooterContainer>
    </ThemeProvider>
  )
}

export default Footer

function useLinks(links) {
  const { slugPrefix } = useLocale()
  return useMemo(
    () =>
      links.map((linkedEntry, index) => {
        if (linkedEntry.__typename === "ContentfulCta") {
          return {
            key: index,
            to: resolveCTALink(linkedEntry),
            label: linkedEntry.label,
          }
        }
        if (linkedEntry.slug && linkedEntry.title) {
          return {
            key: index,
            to: resolveLink(slugPrefix, linkedEntry),
            label: linkedEntry.title,
          }
        }

        return { key: index, to: "/", label: "Unknown" }
      }),
    [links, slugPrefix]
  )
}

function useLinkElements(links, Component) {
  return useMemo(
    () =>
      links.map(({ key, to, element, label }) =>
        to?.endsWith("/privacy-policy") ? null : element ? (
          <Fragment key={key}>{element}</Fragment>
        ) : (
          <StyledLink to={to} key={key}>
            <Component>{label}</Component>
          </StyledLink>
        )
      ),
    [links]
  )
}

function googlePlayBadge(language) {
  switch (language) {
    case "de":
      return GPDe
    case "fr":
      return GPFr
    case "nl":
      return GPNl
    case "en":
    default:
      return GPEn
  }
}

function appStoreBadge(language) {
  switch (language) {
    case "de":
      return ASDe
    case "fr":
      return ASFr
    case "nl":
      return ASNl
    case "en":
    default:
      return ASEn
  }
}

const SectionHeadlineText = styled(CopyStrongText)`
  margin-bottom: ${SPACE_M}px;

  ${breakpoints({
    SM: css`
      margin-bottom: 0;
      height: 72px;
      display: flex;
      align-items: center;
    `,
  })}
`

const FooterContainer = styled.footer`
  padding: ${SPACE_2XL}px ${SPACE_L}px 0;
  display: grid;
  background-color: ${COLOR_GRAY_700};
  color: ${COLOR_TEXT_INVERTED};
  flex-shrink: 0;
  column-gap: 10px;

  ${breakpoints({
    SM: css`
      grid-template-areas:
        "logo"
        "changecountry"
        "product"
        "aboutus"
        "secureshopping"
        "paymentoptions"
        "newsletter"
        "app"
        "followus"
        "bottom";
    `,
    MD: css`
      grid-template-areas:
        "logo           .              changecountry"
        "product        aboutus        newsletter"
        "product        aboutus        secureshopping"
        ".              .              secureshopping"
        "app            followus       paymentoptions"
        "bottom         bottom         bottom";

      grid-template-columns: auto auto 246px;
    `,
    LG: css`
      grid-template-columns: 3fr 2fr 2fr 3fr;
      grid-template-areas:
        "logo   product        aboutus        changecountry"
        ".      product        aboutus        newsletter"
        ".      product        aboutus        secureshopping"
        ".      .              .              secureshopping"
        ".      app            followus       paymentoptions"
        "bottom bottom         bottom         bottom";
    `,
    XL: css`
      grid-template-columns: 1fr 1fr 1fr minmax(300px, 1fr);
      grid-template-areas:
        "logo   product        aboutus        changecountry"
        ".      product        aboutus        newsletter"
        ".      product        aboutus        secureshopping"
        ".      .              .              secureshopping"
        ".      app            followus       paymentoptions"
        "bottom bottom         bottom         bottom";
      padding: ${SPACE_2XL}px
        calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px) 0;
    `,
  })};
`

const ExpandableBlockContainer = styled.div`
  ${breakpoints({
    SM: css`
      border-bottom: 1px solid white;

      // bleed content out of the container
      padding: 0 24px;
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      position: relative;
      right: 50%;
      width: 100vw;
    `,
  })}
`

const SIubendaPolicyLink = styled(IubendaPolicyLink)`${createLinkStyles(
  COLOR_TEXT_INVERTED,
  COLOR_DARK_CORAL,
  false
)}}`

const StyledLink = styled(GLink)`
  ${({ theme }) =>
    createLinkStyles(COLOR_TEXT_INVERTED, COLOR_DARK_CORAL, false)};
`

const CopyrightText = styled(Text)``

const ExpandableBlock = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  ${({ horizontalLinks }) =>
    horizontalLinks
      ? css``
      : css`
          flex-direction: column;
          ${StyledLink} ~ ${StyledLink} {
            margin-top: ${SPACE_M}px;
          }
        `};

  ${breakpoints({
    SM: css`
      padding-bottom: ${SPACE_XL}px;
      ${props =>
        !props.show &&
        css`
          display: none;
        `}
    `,
  })}
`

const FooterLogo = styled.div`
  margin: 0;
  width: 142px;
  grid-area: logo;

  img {
    display: block;
    width: 100%;
  }

  ${breakpoints({
    SM: css`
      width: 124px;
      display: block;
    `,
    LG: css`
      margin-left: 8px;
    `,
    XL: css`
      margin-right: 40px;
    `,
  })}

  // we should avoid custom media queries but header is a bit specific
@media (min-width: 1100px) {
    margin-right: 20px;
  }
`

const SChangeLocaleButton = styled(ChangeLocaleButton)`
  grid-area: changecountry;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_2XL}px;
      margin-bottom: ${SPACE_XL}px;
      width: 100%;
    `,
    MD: css`
      margin-bottom: ${SPACE_2XL}px;
    `,
    LG: css`
      margin-bottom: ${SPACE_XL}px;
    `,
    XL: css`
      margin-bottom: ${SPACE_L}px;
    `,
  })}
`

const ProductLinks = styled(ExpandableBlockContainer)`
  grid-area: product;
  display: flex;
  flex-direction: column;
`

const AboutUsLinks = styled(ExpandableBlockContainer)`
  grid-area: aboutus;
  display: flex;
  flex-direction: column;
`

const PaymentOption = styled.div`
  display: inline-block;
  width: 48px;

  img {
    width: 100%;
    height: 32px;
  }
`

const ExpandableSectionHeadlineText = styled(SectionHeadlineText)`
  display: flex;
  justify-content: space-between;

  svg {
    display: none;
  }

  ${breakpoints({
    SM: css`
      cursor: pointer;
      svg {
        display: inline-block;
      }
    `,
  })}
`

const AppStoreSection = styled.div`
  grid-area: app;
  display: flex;
  flex-direction: column;
`

const SocialMediaSection = styled.div`
  grid-area: followus;

  img {
    display: inline-block;
  }

  ${StyledLink} {
    display: inline-block;
    margin-right: ${SPACE_S + SPACE_XS}px;

    &:first-child {
      margin-bottom: ${SPACE_S + SPACE_XS}px;
    }
  }

  ${breakpoints({
    SM: css`
      margin-bottom: ${SPACE_2XL}px;

      span {
        display: none;
      }
    `,
    MD: css`
      img {
        width: 40px;
      }
    `,
    LG: css`
      img {
        width: 40px;
      }
    `,
    XL: css`
      img {
        width: 40px;
      }
    `,
  })}
`

const NewsletterSection = styled.div`
  grid-area: newsletter;
  margin-bottom: ${SPACE_M}px;
`

const PaymentOptions = styled(ExpandableBlockContainer)`
  grid-area: paymentoptions;

  > ${ExpandableBlock} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
  }

  ${ExpandableSectionHeadlineText} {
    ${breakpoints({
      MD: css`
        padding-top: 0;
        cursor: initial;
        display: block;
      `,
      LG: css`
        cursor: initial;
        display: block;
      `,
    })}
  }
`

const SecureShopping = styled(ExpandableBlockContainer)`
  grid-area: secureshopping;

  ${ExpandableSectionHeadlineText} {
    ${breakpoints({
      MD: css`
        cursor: initial;
        display: block;
      `,
      LG: css`
        cursor: initial;
        display: block;
      `,
    })}
  }
`

const BottomRowContent = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  > * {
    &:not(:first-child) {
      margin-left: ${SPACE_M}px;
    }
    &:not(:last-child) {
      margin-right: ${SPACE_M}px;
    }
  }

  ${breakpoints({
    SM: css`
      padding: ${SPACE_XL}px 0;
      flex-wrap: wrap;

      ${CopyrightText} {
        width: 100%;
        margin-bottom: ${SPACE_XL}px;

        ~ * {
          width: calc(50% - ${SPACE_M / 2}px);
          margin-left: 0;
          margin-right: 0;

          &:nth-of-type(n + 3) {
            margin-top: ${SPACE_M}px;
          }

          &:nth-of-type(2n) {
            margin-left: ${SPACE_M / 2}px;
          }
          &:nth-of-type(2n + 1) {
            margin-right: ${SPACE_M / 2}px;
          }
        }
      }
    `,
    MD: css`
      padding: ${SPACE_XL}px 0;

      width: 100%;

      ${CopyrightText} {
        margin-right: auto;
        display: flex;
        justify-content: space-between;
      }
    `,
    LG: css`
      padding: ${SPACE_XL}px 0;

      position: relative;
      padding-left: 30%;
      display: flex;
      justify-content: center;
      width: 100%;
      white-space: nowrap;

      > ${CopyrightText} {
        margin: 0;
        position: absolute;
        left: 30%;
        transform: translateX(-100%);
      }

      > *:last-child {
        margin-right: 10%;
      }
    `,
    XL: css`
      padding: ${SPACE_XL}px;
      min-width: 50%;
    `,
  })}
`

const BottomRow = styled.div`
  grid-area: bottom;

  display: flex;
  justify-content: center;

  border-top: 1px solid ${COLOR_GRAY_300};

  ${breakpoints({
    MD: css`
      margin-top: ${SPACE_2XL}px;
    `,
    LG: css`
      margin-top: ${SPACE_2XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_2XL}px;
    `,
  })}
`

const NavigationLinkText = styled(props => <Text type="copy" {...props} />)`
  color: inherit;
`

const BottomNavigationLinkText = styled(props => (
  <Text type="meta" {...props} />
))`
  color: inherit;
`

function ToggleIcon({ isExpanded }) {
  return isExpanded ? <MinusIcon color="white" /> : <PlusIcon color="white" />
}

export const footerDataFragment = graphql`
  fragment ContentfulMainFooterData on ContentfulMain {
    footerSocialMediaHeadline
    footerCountrySelectionLabel
    footerPaymentOptionsHeadline
    footerSecureShoppingHeadline

    footerProductNavigationLinksHeadline
    footerProductNavigationLinks {
      __typename
      ... on ContentfulCta {
        ...CTAFragment
      }
      ... on ContentfulPost {
        title
        metaDescription
        slug
      }
      ... on ContentfulCmsPage {
        title
        metaDescription
        ...LinkableCmsPageFragment
      }
    }

    footerCompanyNavigationLinksHeadline
    footerCompanyNavigationLinks {
      __typename
      ... on ContentfulCta {
        ...CTAFragment
      }
      ... on ContentfulPost {
        title
        slug
      }
      ... on ContentfulCmsPage {
        title
        ...LinkableCmsPageFragment
      }
    }

    footerNewsletterHeadline
    footerNewsletterCta {
      ...CTAFragment
    }

    footerAppStoresLinksHeadline

    footerUnuCopyrightText
    footerCookiePolicyLinkLabel
    footerPrivacyPolicyLinkLabel
    cookiePolicyIubendaLink
    privacyPolicyIubendaLink
    footerBottomNavigationLinks {
      __typename
      ... on ContentfulCta {
        ...CTAFragment
      }
      ... on ContentfulPost {
        title
        slug
      }
      ... on ContentfulCmsPage {
        title
        ...LinkableCmsPageFragment
      }
    }
    navigationLinks {
      __typename
      ... on ContentfulPost {
        id
      }
      ... on ContentfulCmsPage {
        id
        title
        ...LinkableCmsPageFragment
      }
    }
  }
`
