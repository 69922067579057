import { navigate } from "gatsby-link"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import styled, { createGlobalStyle, css } from "styled-components"
import { useGlobalTranslation } from "../../globalValues"
import { useLocale } from "../../locale"
import {
  COLOR_GRAY_100,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { resolveLink } from "../../utils/linkResolver"
import { useSubmitButtonState } from "../input/hooks"

import { Modal2 } from "../Modal2"
import { RichText } from "../RichText"
import { SelectInput } from "../SelectInput"
import { Text } from "../Text"
import { breakpoints } from "../../styles"
import { localeSuggestionDismissItemAPI } from "./useLocaleDecision"
import { SubmitButton } from "../input"
import { siteLanguagesByCountries } from "../../config"
import { IUBENDA_BANNER_Z_INDEX } from "../GlobalIubendaStyles"

const CITY_TRANSITION_DURATION = 500

export function LocaleModal({
  localizedNodes,
  mainData: {
    localeModalHeadline,
    localeModalCountryLabel,
    localeModalLanguageLabel,
    localeModalShippingCities,
    localeModalFormDescription,
    localeModalFormSubmitButtonLabel,
  },
  onRequestClose,
}) {
  const languageKeyToValue = localeMappingStringsToMap(
    useGlobalTranslation("languages")
  )
  const languageValueToKey = useMemo(() => {
    const result = {}
    for (const key in languageKeyToValue) {
      result[languageKeyToValue[key]] = key
    }
    return result
  }, [languageKeyToValue])
  const countryKeyToValue = localeMappingStringsToMap(
    useGlobalTranslation("countries")
  )
  const countryValueToKey = useMemo(() => {
    const result = {}
    for (const key in countryKeyToValue) {
      result[countryKeyToValue[key]] = key
    }
    return result
  }, [countryKeyToValue])

  const {
    language: currentPageLanguage,
    country: currentPageCountry,
  } = useLocale()
  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      language: languageKeyToValue[currentPageLanguage],
      country: countryKeyToValue[currentPageCountry],
    },
  })
  const currentCountry = watch("country")

  const handleChange = useCallback(
    ({ country: newCountryValue, language: languageValue }) => {
      const newCountryKey = countryValueToKey[newCountryValue]
      if (
        !siteLanguagesByCountries[newCountryKey].includes(
          languageValueToKey[languageValue]
        )
      ) {
        const newLanguageKey = siteLanguagesByCountries[newCountryKey][0]
        setValue("language", languageKeyToValue[newLanguageKey])
      }
    },
    [countryValueToKey, languageKeyToValue, languageValueToKey, setValue]
  )
  watch(handleChange)

  const submitButtonState = useSubmitButtonState(formState)

  const countryOptions = useMemo(
    () =>
      Object.keys(siteLanguagesByCountries).map(
        countryKey => countryKeyToValue[countryKey]
      ),
    [countryKeyToValue]
  )
  const languageOptions = useMemo(
    () =>
      siteLanguagesByCountries[countryValueToKey[currentCountry]].map(
        languageKey => languageKeyToValue[languageKey]
      ),
    [countryValueToKey, currentCountry, languageKeyToValue]
  )

  const submit = useCallback(
    ({ language, country }) => {
      const languageKey = Object.keys(languageKeyToValue).find(
        key => languageKeyToValue[key] === language
      )
      const countryKey = Object.keys(countryKeyToValue).find(
        key => countryKeyToValue[key] === country
      )

      const slugPrefix = `${languageKey}-${countryKey}`
      localeSuggestionDismissItemAPI.setItem(true)
      console.log(
        "Cookie set to",
        `unu_country=${countryKey}; domain=unumotors.com; path=/`
      )
      console.log(
        "Cookie set to",
        `unu_language=${languageKey}; domain=unumotors.com; path=/`
      )
      document.cookie = `unu_country=${countryKey}; domain=unumotors.com; path=/`
      document.cookie = `unu_language=${languageKey}; domain=unumotors.com; path=/`

      const node = (
        getLocalizedNodeLink(localizedNodes, languageKey, countryKey) ||
        slugPrefix
      ).replace("#", `/${slugPrefix}`)

      // console.log("Node", localizedNodes, node)

      navigate(`${node}${window.location.hash}${window.location.search}`)
      onRequestClose()
    },
    [countryKeyToValue, languageKeyToValue, localizedNodes, onRequestClose]
  )

  const [currentCityIndex, setCurrentCityIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentCityIndex(current =>
        localeModalShippingCities.length === current + 1 ? 0 : current + 1
      )
    }, 3000)

    return () => clearInterval(intervalId)
  }, [localeModalShippingCities.length])

  return (
    <>
      <CustomGlobalModalStyles />

      <Modal2 onClose={onRequestClose} overlayClassName="LocaleModal__Overlay">
        <Content>
          <ModalHeroText type="hero">
            {localeModalHeadline}
            <CityTransitionContainer>
              <SwitchTransition>
                <CSSTransition
                  classNames="transition"
                  timeout={{
                    enter: CITY_TRANSITION_DURATION,
                    exit: CITY_TRANSITION_DURATION,
                  }}
                  key={currentCityIndex}
                >
                  <City>{localeModalShippingCities[currentCityIndex]}</City>
                </CSSTransition>
              </SwitchTransition>
            </CityTransitionContainer>
          </ModalHeroText>
          <Form onSubmit={handleSubmit(submit)}>
            <FieldHeadlineText type="tertiary">
              {localeModalCountryLabel}
            </FieldHeadlineText>
            <DescriptionRichText
              data={localeModalFormDescription}
              keepTopLevelParagraph={true}
            />
            <StyledSelect
              options={countryOptions}
              {...register("country", { required: true })}
            />

            <FieldHeadlineText type="tertiary">
              {localeModalLanguageLabel}
            </FieldHeadlineText>
            <StyledSelect
              options={languageOptions}
              {...register("language", { required: true })}
            />

            <SSubmitButton
              variant="primary"
              submitButtonState={submitButtonState}
            >
              {localeModalFormSubmitButtonLabel}
            </SSubmitButton>
          </Form>
        </Content>
      </Modal2>
    </>
  )
}

function localeMappingStringsToMap(mappingStrings) {
  return mappingStrings.reduce((result, mappingString) => {
    const [key, value] = mappingString.split(":")
    return { ...result, [key]: value }
  }, {})
}

const Content = styled.div`
  display: grid;
  grid-template-areas: "headline form";
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${SPACE_L}px;

  ${breakpoints({
    SM: css`
      grid-template-areas: "form";
      grid-template-columns: auto;
    `,
    MD: css`
      grid-template-areas:
        "headline"
        "form";
      grid-template-columns: auto;
    `,
    LG: css`
      grid-template-areas:
        "headline"
        "form";
      grid-template-columns: auto;
    `,
  })}
`

const ModalHeroText = styled(Text)`
  grid-area: headline;
  padding-top: ${SPACE_3XL}px;
  margin-bottom: ${SPACE_XL}px;

  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}
`

const Form = styled.form`
  grid-area: form;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_XL}px 0 0;
    `,
    MD: css`
      padding: ${SPACE_2XL}px;
      background-color: ${COLOR_GRAY_100};
    `,
    LG: css`
      padding: ${SPACE_2XL}px;
      background-color: ${COLOR_GRAY_100};
    `,
    XL: css`
      padding: ${SPACE_2XL}px;
      background-color: ${COLOR_GRAY_100};
    `,
  })}
`

const FieldHeadlineText = styled(Text)``

const DescriptionRichText = styled(RichText)`
  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_M}px;
    `,
    MD: css`
      margin-top: ${SPACE_M}px;
    `,
    LG: css`
      margin-top: ${SPACE_XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_XL}px;
    `,
  })}
`

const StyledSelect = styled(SelectInput)`
  margin-bottom: ${SPACE_XL + SPACE_S}px;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_M}px;
    `,
    MD: css`
      margin-top: ${SPACE_M}px;
    `,
    LG: css`
      margin-top: ${SPACE_L}px;
    `,
    XL: css`
      margin-top: ${SPACE_XL}px;
    `,
  })}
`

const SSubmitButton = styled(SubmitButton)`
  width: 100%;
`

const City = styled.div`
  transition: transform ease-in-out ${CITY_TRANSITION_DURATION}ms;
  transform-origin: center;
  transform: rotateX(0deg);

  &.transition-enter {
    z-index: 2;
    transform: rotateX(90deg);
  }
  &.transition-enter-active {
    z-index: 2;
    transform: rotateX(0deg);
  }
  &.transition-exit {
    transform: rotateX(0deg);
  }
  &.transition-exit-active {
    z-index: 1;
    transform: rotateX(90deg);
  }
`

const CityTransitionContainer = styled.div`
  position: relative;
`

export function getLocalizedNodeLink(localizedNodes, languageKey, countryKey) {
  const localizedEntry = (localizedNodes || []).find(node =>
    node.node_locale.startsWith(languageKey)
  )

  const slugPrefix = `${languageKey}-${countryKey}`
  return localizedEntry ? resolveLink(slugPrefix, localizedEntry) : null
}

const CustomGlobalModalStyles = createGlobalStyle`
  .ReactModal__Overlay.LocaleModal__Overlay {
    z-index: ${IUBENDA_BANNER_Z_INDEX + 1};
  }
`
