import React, { useMemo } from "react"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled, { css } from "styled-components"
import {
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_3XL,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_XL,
} from "../styles/primitives"
import {
  SHeadlineLevel5Text,
  SHeadlineLevel6Text,
  SHeadlinePrimaryText,
  SHeadlineSecondaryText,
  SHeadlineTertiaryText,
  Text,
} from "./Text"
import { Link } from "../components/links"
import { List, ListItem } from "./lists"
import { Media } from "./Media"
import { handleShortCodes } from "../utils/shortcode/shortcodes"

const Root = styled.div`
  white-space: pre-wrap;
`

export const RichText = ({
  data: { raw, references = [] },
  children,
  keepTopLevelParagraph = false,
  components,
  openLinksInNewTab = false,
  ...otherProps
}) => {
  const finalComponents = useMemo(
    () => ({
      Headline1Text: SRichTextHeadline1Text,
      Headline2Text: SRichTextHeadline2Text,
      Headline3Text: SRichTextHeadline3Text,
      Headline4Text: SRichTextHeadline4Text,
      Headline5Text: SRichTextHeadline5Text,
      Headline6Text: SRichTextHeadline6Text,
      ParagraphText: RichTextParagraphText,
      Link: SRichTextLink,
      ...components,
    }),
    [components]
  )
  const referencesMap = useMemo(() => {
    const map = {
      entries: {},
      assets: {},
    }
    for (const reference of references) {
      if (!reference.contentful_id) {
        console.error(
          "Unknown richtext reference. You are probably missing this referenced type in your GraphQL query. `contentful_id` wass missing in:",
          reference
        )
        continue
      }

      if (reference.__typename === "ContentfulAsset") {
        map.assets[reference.contentful_id] = reference
      } else {
        map.entries[reference.contentful_id] = reference
      }
    }
    return map
  }, [references])

  const preparedData = useMemo(() => {
    const parsed = typeof raw === "string" ? JSON.parse(raw) : raw

    keepTopLevelParagraph || normalizeSingleParagrap(parsed)
    addListItemIndexes(parsed)

    return parsed
  }, [keepTopLevelParagraph, raw])
  const renderedContent = useMemo(
    () => {
      let doc = documentToReactComponents(preparedData, {
        renderNode: {
          [BLOCKS.DOCUMENT]: (node, children) => children,
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <finalComponents.ParagraphText
              type="copy"
              keepTopLevelParagraph={keepTopLevelParagraph}
            >
              {children}
            </finalComponents.ParagraphText>
          ),

          [BLOCKS.HEADING_1]: (node, children) => (
            <finalComponents.Headline1Text>
              {children}
            </finalComponents.Headline1Text>
          ),
          [BLOCKS.HEADING_2]: (node, children) => (
            <finalComponents.Headline2Text>
              {children}
            </finalComponents.Headline2Text>
          ),
          [BLOCKS.HEADING_3]: (node, children) => (
            <finalComponents.Headline3Text>
              {children}
            </finalComponents.Headline3Text>
          ),
          [BLOCKS.HEADING_4]: (node, children) => (
            <finalComponents.Headline4Text>
              {children}
            </finalComponents.Headline4Text>
          ),
          [BLOCKS.HEADING_5]: (node, children) => (
            <finalComponents.Headline5Text>
              {children}
            </finalComponents.Headline5Text>
          ),
          [BLOCKS.HEADING_6]: (node, children) => (
            <finalComponents.Headline6Text>
              {children}
            </finalComponents.Headline6Text>
          ),
          [BLOCKS.OL_LIST]: (node, children) => (
            <StyledList isOrdered={true}>{children}</StyledList>
          ),
          [BLOCKS.UL_LIST]: (node, children) => (
            <StyledList isOrdered={false}>{children}</StyledList>
          ),
          [BLOCKS.LIST_ITEM]: (node, children, ...args) => (
            <StyledListItem index={node.data.index}>{children}</StyledListItem>
          ),
          [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            if (!referencesMap.assets[node.data.target.sys.id]) {
              console.error(
                `Missing asset for embedding (asset id: ${node.data.target.sys.id})`
              )
              return "[missing embedded asset]"
            }

            return (
              <StyledMedia
                media={referencesMap.assets[node.data.target.sys.id]}
                objectFit="contain"
              />
            )
          },

          [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            if (!referencesMap.entries[node.data.target.sys.id]) {
              console.error(
                `Missing asset for embedding (entry id: ${node.data.target.sys.id})`
              )
              return "[missing embedded entry]"
            }

            return (
              <StyledMedia
                media={referencesMap.entries[node.data.target.sys.id]}
                objectFit="contain"
              />
            )
          },

          [INLINES.HYPERLINK]: (node, children) => {
            return (
              <finalComponents.Link to={node.data.uri}>
                {children}
              </finalComponents.Link>
            )
          },
          [INLINES.ENTRY_HYPERLINK]: (node, children) => {
            if (!referencesMap.entries[node.data.target.sys.id]) {
              console.error(
                `Missing entry reference for linking (entry id: ${node.data.target.sys.id})`
              )
              return "[missing entry link]"
            }

            if (openLinksInNewTab) {
              return (
                <finalComponents.Link
                  to={referencesMap.entries[node.data.target.sys.id]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {children}
                </finalComponents.Link>
              )
            }

            return (
              <finalComponents.Link
                to={referencesMap.entries[node.data.target.sys.id]}
              >
                {children}
              </finalComponents.Link>
            )
          },
          [INLINES.ASSET_HYPERLINK]: (node, children) => {
            if (!referencesMap.assets[node.data.target.sys.id]) {
              console.error(
                `Missing asset reference for linking (asset id: ${node.data.target.sys.id})`
              )
              return "[missing asset link]"
            }

            if (openLinksInNewTab) {
              return (
                <finalComponents.Link
                  to={referencesMap.assets[node.data.target.sys.id]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {children}
                </finalComponents.Link>
              )
            }

            return (
              <finalComponents.Link
                to={referencesMap.assets[node.data.target.sys.id]}
              >
                {children}
              </finalComponents.Link>
            )
          },
        },
        renderMark: {
          [MARKS.BOLD]: text => <strong>{text}</strong>,
          [MARKS.ITALIC]: text => <em>{text}</em>,
          [MARKS.UNDERLINE]: text => <Underlined>{text}</Underlined>,
          [MARKS.CODE]: text => <code>{text}</code>,
        },
      })

      doc = handleShortCodes(doc)

      return doc
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preparedData, referencesMap.assets, referencesMap.entries]
  )

  return (
    <Root {...otherProps}>
      {renderedContent}
      {children ? <> {children}</> : null}
    </Root>
  )
}

const Underlined = styled.span`
  text-decoration: underline;
`

const StyledMedia = styled(Media)`
  margin-top: ${SPACE_TOKEN_STACK_3XL}px;
  margin-bottom: ${SPACE_TOKEN_STACK_3XL}px;
  max-height: 600px;
`

export const RichTextParagraphText = styled(Text)`
  margin-bottom: ${SPACE_TOKEN_STACK_M}px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ keepTopLevelParagraph }) =>
    keepTopLevelParagraph ||
    css`
      color: inherit;
    `};
  white-space: pre-wrap;
`

const StyledList = styled(List)``

const StyledListItem = styled(ListItem)`
  ${RichTextParagraphText} {
    padding-left: 0;
    padding-right: 0;
  }
`

function addListItemIndexes(node) {
  if (node.nodeType === "ordered-list") {
    node.content = node.content || []
    node.content.forEach((listItemChild, index) => {
      listItemChild.data.index = index
    })
  }

  for (const child of node.content || []) {
    addListItemIndexes(child)
  }
}

function normalizeSingleParagrap(node) {
  if (
    node.nodeType === "document" &&
    node.content.length === 1 &&
    node.content[0].nodeType === "paragraph"
  ) {
    node.content = node.content[0].content
  }
  return node
}

export const SRichTextHeadline1Text = styled(SHeadlinePrimaryText)`
  padding: ${SPACE_TOKEN_STACK_2XL}px 0;
  &:first-child {
    padding-top: 0;
  }
`

const commonHeadlineStyles = css`
  padding: ${SPACE_TOKEN_STACK_XL}px 0;

  &:first-child {
    padding-top: 0;
  }
`

export const SRichTextHeadline2Text = styled(SHeadlineSecondaryText)`
  ${commonHeadlineStyles};
`

export const SRichTextHeadline3Text = styled(SHeadlineTertiaryText)`
  ${commonHeadlineStyles};
`

export const SRichTextHeadline4Text = styled(SHeadlineTertiaryText)`
  ${commonHeadlineStyles};
`

export const SRichTextHeadline5Text = styled(SHeadlineLevel5Text)`
  ${commonHeadlineStyles};
`

export const SRichTextHeadline6Text = styled(SHeadlineLevel6Text)`
  ${commonHeadlineStyles};
`

export const SRichTextLink = styled(Link)``
