import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import { SPACE_L, SPACE_S } from "../styles/primitives"
import { useInputStyle, inputFontStyles } from "./input"

export const SelectInput = forwardRef(
  (
    {
      name,
      options,
      onChange,
      onBlur,
      value,
      defaultValue,
      width,
      onFocus,
      className,
      disabled = false,
      variant = "light",
      errors,
      rounded = false,
      ...otherProps
    },
    ref
  ) => {
    const { variantConfig, isFocused, handleFocus, handleBlur } = useInputStyle(
      {
        errors,
        name,
        variant,
        onFocus,
        onBlur,
        disabled,
      }
    )
    const optionItems = options.map(item =>
      typeof item === "object" ? (
        <option
          key={item.value}
          value={item.value}
          disabled={item.disabled || false}
        >
          {item.label}
        </option>
      ) : (
        <option key={item} value={item}>
          {item}
        </option>
      )
    )

    return (
      <Root
        className={className}
        variantConfig={variantConfig}
        isFocused={isFocused}
        rounded={rounded}
      >
        <SelectStyle
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          width={width}
          name={name}
          ref={ref}
          defaultValue={defaultValue}
          {...otherProps}
        >
          {optionItems}
        </SelectStyle>
      </Root>
    )
  }
)

const SelectStyle = styled.select`
  ${inputFontStyles};

  height: 100%;
  box-sizing: border-box;
  display: block;

  padding: 0;
  width: ${props => (props.width ? props.width : "100%")};
  max-width: 100%; /* useful when width is set to anything other than 100% */

  border-radius: 0;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;

  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 13.1893L15.7806 9.21967C16.0596 8.92678 16.5118 8.92678 16.7908 9.21967C17.0697 9.51256 17.0697 9.98744 16.7908 10.2803L12.5051 14.7803C12.2261 15.0732 11.7739 15.0732 11.4949 14.7803L7.20921 10.2803C6.93026 9.98744 6.93026 9.51256 7.20921 9.21967C7.48816 8.92678 7.94042 8.92678 8.21936 9.21967L12 13.1893Z' fill='%23141414'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.1em top 0.4em;
  background-size: 24px auto;

  // hide arrow in MS
  &::-ms-expand {
    display: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  option {
    font-weight: normal;
  }

  // Disabled styles
  &:disabled,
  &[aria-disabled="true"] {
    color: graytext;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  }

  &:disabled:hover,
  &[aria-disabled="true"] {
    border-color: #aaa;
  }
`

const Root = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  ${SelectStyle} {
    margin-left: ${SPACE_L - SPACE_S}px;
    margin-right: ${SPACE_S}px;
  }

  ${({ rounded, variantConfig, isFocused }) =>
    css`
      border-radius: ${rounded ? 100 : 8}px;
      ${isFocused
        ? createStyle(variantConfig.active)
        : createStyle(variantConfig.default)};

      &:hover {
        ${createStyle(variantConfig.active)};
      }
    `}
`

function createStyle(stateStyle) {
  return css`
    border: ${stateStyle.borderWidth}px solid ${stateStyle.borderColor};
    background-color: ${stateStyle.backgroundColor};
  `
}
