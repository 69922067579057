import React from "react"
import styled, { css } from "styled-components"
import { ArrowRightIcon } from "../../icons/ArrowRightIcon"
import { COLOR_UI_BACKGROUND_PRIMARY, useTheme } from "../../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"
import { COLOR_GRAY_100, COLOR_SUCCESS } from "../../styles/primitives"
import { CheckmarkIcon } from "../../icons/CheckmarkIcon"
import { Button } from "../button"
import { SSwapTransition, swapTransitionParentStyles } from "../SwapTransition"
import { LoaderIcon } from "../../icons/icons"

const StyledArrowRightIcon = styled(ArrowRightIcon)``
const StyledCheckmarkIcon = styled(CheckmarkIcon)``

const stylesByState = {
  default: {
    INITIAL: css`
      ${StyledArrowRightIcon} {
        stroke: ${COLOR_GRAY_100};
      }

      &:hover {
        ${StyledArrowRightIcon} {
          stroke: ${COLOR_UI_BACKGROUND_PRIMARY};
        }
      }
    `,
    SUBMITTING: css`
      ${StyledArrowRightIcon} {
        stroke: ${COLOR_GRAY_100};
      }
    `,
    SUCCESS: css`
      background-color: ${COLOR_SUCCESS};
      border-color: ${COLOR_SUCCESS};
    `,
    INVALID: css`
      ${StyledArrowRightIcon} {
        stroke: ${COLOR_GRAY_100};
      }
    `,
  },
  coral: {
    INITIAL: css`
      ${StyledArrowRightIcon} {
        stroke: ${COLOR_UI_BACKGROUND_PRIMARY};
      }
    `,
    SUBMITTING: css`
      ${StyledArrowRightIcon} {
        stroke: ${COLOR_GRAY_100};
      }
    `,
    SUCCESS: css`
      background-color: transparent;
    `,
    INVALID: css`
      ${StyledArrowRightIcon} {
        stroke: ${COLOR_UI_BACKGROUND_PRIMARY};
      }
    `,
  },
}

export const StyledSubmitButton = styled(Button)`
  appearance: none;
  outline: none;
  padding: 0;

  transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  height: 40px;
  width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledArrowRightIcon} {
    width: 16px;
    height: 16px;
    stroke-width: 4;
    transition: stroke ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  }

  ${({ theme, state }) =>
    (theme.themeName === "coral" ? stylesByState.coral : stylesByState.default)[
      state
    ]}
`

const SRoundedSubmitButton = styled(SubmitButton)`
  min-width: auto;
  border-radius: 50%;
`

export function IconSubmitButton({ submitButtonState, ...otherProps }) {
  return (
    <SRoundedSubmitButton
      submitButtonState={submitButtonState}
      variant="primaryInverted"
      {...otherProps}
    >
      {null}
    </SRoundedSubmitButton>
  )
}

export function SubmitButton({
  submitButtonState,
  children,
  variant,
  ...otherProps
}) {
  const { theme } = useTheme()

  return (
    <StyledSubmitButton
      type="submit"
      variant={
        variant
          ? variant
          : submitButtonState === "SUCCESS"
          ? "success"
          : "primary"
      }
      disabled={submitButtonState === "INVALID"}
      state={submitButtonState}
      {...otherProps}
    >
      {children === null ? null : children === undefined ? (
        <span>Send</span>
      ) : (
        <span>{children}</span>
      )}
      <SSwapTransitionParent>
        <SSwapTransition isVisible={submitButtonState === "SUBMITTING"}>
          <SLoaderIcon dark={variant === "primaryInverted"} />
        </SSwapTransition>
        <SSwapTransition isVisible={submitButtonState !== "SUBMITTING"}>
          <SubmitButtonIcon submitButtonState={submitButtonState} />
        </SSwapTransition>
      </SSwapTransitionParent>
    </StyledSubmitButton>
  )
}

function SubmitButtonIcon({ submitButtonState }) {
  return submitButtonState === "SUCCESS" ? (
    <StyledCheckmarkIcon color={COLOR_SUCCESS} />
  ) : (
    <StyledArrowRightIcon />
  )
}

const SSwapTransitionParent = styled.div`
  ${swapTransitionParentStyles};
  align-items: center;
  justify-content: flex-start;
`

const SLoaderIcon = styled(LoaderIcon)`
  display: block;
`
