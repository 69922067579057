import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"
import { breakpoints, ThemeProvider } from "../styles"
import UnuLogo from "../images/UNO-EMCO-Logo-Finals-CS-24-vs01-C2 1.svg"
import MenuIcon from "../images/menu.svg"
import CloseIcon from "../images/close.svg"
import { Button } from "./button"
import { MAX_WIDTH } from "../styles/breakpoints"
import {
  COLOR_STONE,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../styles/primitives"
import { Link } from "./links"
import { LocaleSuggestionBar } from "./locale"
import { graphql } from "gatsby"
import { useLocaleDecision } from "./locale"
import { useEffect, useState } from "react"

const Header = ({
  showMenu,
  toggleMenu,
  slugPrefix,
  mainData,
  localizedNodes,
  logoOnlyHeader,
  ...otherProps
}) => {
  const buyUnuText = mainData.buyUnu.title ?? "Shop"
  mainData.buyUnu = "https://shop.unumotors.com" // IF OLD SHOP IS FULLY DECOMISSIONED, REMOVE THIS AND USE CONTENTFUL

  const testRideText = mainData.testRide.label
  const testRideMobileText = mainData.testRideMobileText

  const [isCountryDE, setIsCountryDE] = useState(false)

  useEffect(() => {
    const urlPathname = window.location.pathname
    setIsCountryDE(urlPathname.includes("-de"))
  }, [])

  const {
    shouldSuggestDifferentLocale,
    dismissSuggestion,
  } = useLocaleDecision()

  return (
    <ThemeProvider themeName="stone">
      {shouldSuggestDifferentLocale && (
        <LocaleSuggestionBar
          mainData={mainData}
          localizedNodes={localizedNodes}
          onDismiss={dismissSuggestion}
        />
      )}
      <HeaderContainer {...otherProps}>
        <HeaderContainerInner>
          <HeaderLogo>
            <Link to={mainData.mainLinkHomepagelogo}>
              <img src={UnuLogo} alt="Unu" />
            </Link>
          </HeaderLogo>

          {!logoOnlyHeader && (
            <>
              <MobileHeaderActions showMenu={showMenu}>
                {!isCountryDE ? (
                  <HeaderButtonLink to={mainData.testRide.externalLink}>
                    <HeaderButton variant="secondary">
                      {testRideMobileText}
                    </HeaderButton>
                  </HeaderButtonLink>
                ) : (
                  <HeaderButtonLink to={mainData.subscription.externalLink}>
                    <HeaderButton variant="secondary">
                      {mainData.subscription.label}
                    </HeaderButton>
                  </HeaderButtonLink>
                )}
                <HeaderButtonLink to={mainData.buyUnu} target="_self">
                  <HeaderButton variant="primary">{buyUnuText}</HeaderButton>
                </HeaderButtonLink>
              </MobileHeaderActions>

              <HeaderContent showMenu={showMenu}>
                <HeaderInnerContent>
                  <HeaderLinks>
                    <HeaderNav>
                      <ul>
                        {mainData.navigationLinks.map((linkedEntry, id) => {
                          if (linkedEntry.__typename === "ContentfulCta") {
                            return (
                              <li key={id}>
                                <Link
                                  to={linkedEntry.externalLink}
                                  target={"_self"}
                                >
                                  {linkedEntry.label}
                                </Link>
                              </li>
                            )
                          } else {
                            return (
                              <li key={linkedEntry.id}>
                                <Link to={linkedEntry}>
                                  {linkedEntry.title}
                                </Link>
                              </li>
                            )
                          }
                        })}
                        {isCountryDE && (
                          <li key={mainData.testRide.id}>
                            <Link to={mainData.testRide.externalLink}>
                              {mainData.testRide.label}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </HeaderNav>
                  </HeaderLinks>

                  <HeaderActions>
                    {!isCountryDE ? (
                      <HeaderButtonLink to={mainData.testRide.externalLink}>
                        <HeaderButton variant="secondary">
                          <MobileButtonText>
                            {testRideMobileText}
                          </MobileButtonText>
                          <ButtonText>{testRideText}</ButtonText>
                        </HeaderButton>
                      </HeaderButtonLink>
                    ) : (
                      <HeaderButtonLink to={mainData.subscription.externalLink}>
                        <HeaderButton variant="secondary">
                          <MobileButtonText>
                            {mainData.subscription.label}
                          </MobileButtonText>
                          <ButtonText>{mainData.subscription.label}</ButtonText>
                        </HeaderButton>
                      </HeaderButtonLink>
                    )}
                    <HeaderButtonLink to={mainData.buyUnu} target="_self">
                      <HeaderButton variant="primary">
                        {buyUnuText}
                      </HeaderButton>
                    </HeaderButtonLink>
                  </HeaderActions>
                </HeaderInnerContent>
              </HeaderContent>

              <HeaderMenu onClick={toggleMenu}>
                {showMenu ? (
                  <img src={CloseIcon} alt="close-menu" />
                ) : (
                  <img src={MenuIcon} alt="menu" />
                )}
              </HeaderMenu>
            </>
          )}
        </HeaderContainerInner>
      </HeaderContainer>
    </ThemeProvider>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const HeaderContainer = styled.div`
  background: ${COLOR_STONE};
  position: sticky;
  top: 0;
  z-index: 1;
`

export const HEADER_HEIGHTS = { SM: 56, MD: 80, LG: 80, XL: 80 }

const HeaderContainerInner = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: ${HEADER_HEIGHTS.LG}px;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  padding-left: ${SPACE_XL}px;
  padding-right: ${SPACE_XL}px;

  ${breakpoints({
    SM: css`
      height: ${HEADER_HEIGHTS.SM}px;
      padding-left: ${SPACE_L}px;
      padding-right: ${SPACE_L}px;
    `,
    MD: css`
      height: ${HEADER_HEIGHTS.MD}px;
      padding-left: ${SPACE_L}px;
      padding-right: ${SPACE_L}px;
    `,
    XL: css`
      height: ${HEADER_HEIGHTS.XL}px;
      padding-left: ${SPACE_XL}px;
      padding-right: ${SPACE_XL}px;
    `,
  })}
`
const HeaderLogo = styled.div`
  margin: 0;
  width: 142px;

  img {
    display: block;
    width: 100%;
  }

  ${breakpoints({
    SM: css`
      width: 124px;
      display: block;
    `,
    LG: css``,
    XL: css`
      margin-right: 40px;
    `,
  })}

  // we should avoid custom media queries but header is a bit specific
  @media (min-width: 1100px) {
    margin-right: 20px;
  }
`

const HeaderNav = styled.nav`
  display: flex;
  align-items: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
  }

  li + li {
    margin-left: ${SPACE_XL}px;
  }

  a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;

    color: #333333;
    text-decoration: none;
  }

  ${breakpoints({
    SM: css`
      display: block;

      li {
        display: block;
      }

      li + li {
        margin-left: 0;
        margin-top: ${SPACE_M}px;
      }

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.01em;
      }
    `,
    MD: css`
      align-items: normal;

      li {
        display: block;
      }

      li + li {
        margin-left: 0;
        margin-top: 40px;
      }

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 41px;
        line-height: 110%;
        letter-spacing: -0.02em;
      }
    `,
    LG: css`
      align-items: normal;

      li {
        display: block;
      }

      li + li {
        margin-left: 0;
        margin-top: 40px;
      }

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 41px;
        line-height: 110%;
        letter-spacing: -0.02em;
      }
    `,
    XL: css`
      a {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: #333333;
      }
    `,
  })}
`

const showMenuStyles = breakpoints({
  SM: css`
    display: flex;
  `,
  MD: css`
    display: flex;
  `,
  LG: css`
    display: flex;
  `,
  XL: css`
    display: flex;
    position: static;
  `,
})

const hideMenuOnMobileStyles = breakpoints({
  SM: css`
    display: none;
  `,
  MD: css`
    display: none;
  `,
  LG: css`
    display: none;
  `,
  XL: css`
    display: flex;
    position: static;
  `,
})

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: ${COLOR_STONE};

  ${props => (props.showMenu ? showMenuStyles : hideMenuOnMobileStyles)}

  ${breakpoints({
    SM: css`
      position: fixed;
      top: 56px;
    `,
    MD: css`
      position: fixed;
      top: 80px;
    `,
    LG: css`
      position: fixed;
      top: 80px;
    `,
    XL: css`
      position: static;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
    `,
  })}
`

const HeaderInnerContent = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints({
    SM: css`
      position: absolute;
      overflow: auto;
      top: 0;
      left: ${SPACE_L}px;
      right: 0;
      bottom: 0;
      justify-content: space-between;
      padding-bottom: 40px;
      padding-right: ${SPACE_L}px;
    `,
    MD: css`
      position: absolute;
      overflow: auto;
      top: 0;
      left: ${SPACE_L}px;
      right: 0;
      bottom: 0;
      justify-content: space-between;
      padding-bottom: 40px;
      padding-right: ${SPACE_L}px;
    `,
    LG: css`
      position: absolute;
      overflow: auto;
      top: 0;
      left: ${SPACE_L}px;
      right: 0;
      bottom: 0;
      justify-content: space-between;
      padding-bottom: 40px;
      padding-right: ${SPACE_L}px;
    `,
    XL: css`
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
    `,
  })}
`

const HeaderLinks = styled.div`
  display: flex;

  h1 {
    margin-right: 125px;
  }

  ${breakpoints({
    SM: css`
      flex: 1;
      margin-top: 16vh;
    `,
    MD: css`
      flex: 1;
      margin-top: 16vh;
    `,
    LG: css`
      flex: 1;
      margin-top: 16vh;
    `,
  })}
`

const HeaderActions = styled.div`
  display: flex;
  /* adds min margin to separate buttons from links on small screens */
  ${breakpoints({
    SM: css`
      flex-direction: column;
      margin-top: ${SPACE_1XL}px;
    `,
    MD: css`
      display: none;
    `,
    LG: css`
      display: none;
    `,
  })}
`

const HeaderButton = styled(Button)`
  min-width: 0;
  width: 182px;

  ${breakpoints({
    SM: css`
      // width: 128px; // in header
      width: 100%; // in menu
      height: 40px;
    `,
    MD: css`
      width: 245px; // in menu
      // width: 164px; // in header
      height: ${SPACE_1XL}px;
    `,
    LG: css`
      width: 164px;
      height: ${SPACE_1XL}px;
    `,
    XL: css`
      width: 182px;
      height: ${SPACE_1XL}px;
    `,
  })}
`

let HeaderButtonLink = styled(Link)`
  & + & {
    margin-left: ${SPACE_L}px;
  }

  ${breakpoints({
    SM: css`
      & + & {
        margin-left: 0;
      }

      & + & ${HeaderButton} {
        margin-top: ${SPACE_M}px;
      }
    `,
    MD: css`
      & + & {
        margin-left: ${SPACE_L}px;
      }
    `,
    LG: css`
      & + & {
        margin-left: ${SPACE_M}px;
      }
    `,
    XL: css`
      & + & {
        margin-left: ${SPACE_L}px;
      }
    `,
  })}
`

const MobileButtonText = styled.span`
  display: none;
  ${breakpoints({
    SM: css`
      display: block;
    `,
  })}
`

const ButtonText = styled.span`
  display: inline;
  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}
`

const VisibleMobileHeaderActions = breakpoints({
  SM: css`
    display: block;
    margin-left: auto;
    margin-right: 40px;

    ${HeaderButton} {
      min-width: 128px;
    }

    ${HeaderButtonLink}:not(:first-child) {
      display: none;
    }
  `,
})

const VisibleTableInlineActions = breakpoints({
  MD: css`
    display: block;
    margin-left: auto;
    margin-right: 72px;

    ${HeaderButton} {
      min-width: 164px;
      width: auto;
    }

    ${HeaderButton} + ${HeaderButton} {
      margin-left: ${SPACE_L}px;
      width: auto;
    }
  `,
  LG: css`
    display: block;
    margin-left: auto;
    margin-right: 72px;

    ${HeaderButton} {
      min-width: 164px;
      width: auto;
    }

    ${HeaderButtonLink} + ${HeaderButtonLink} {
      margin-left: ${SPACE_L}px;
      width: auto;
    }
  `,
})

const MobileHeaderActions = styled.div`
  display: none;

  ${VisibleTableInlineActions}
  ${props => (props.showMenu ? null : VisibleMobileHeaderActions)}
`

const HeaderMenu = styled.div`
  display: none;
  transition: opacity ease-out 0.2s;
  cursor: pointer;
  position: absolute;
  right: ${SPACE_L}px;

  &:hover {
    opacity: 0.75;
  }

  ${breakpoints({
    SM: css`
      display: block;
    `,
    MD: css`
      display: block;
    `,
    LG: css`
      display: block;
    `,
  })}
`

export const mainDataFragment = graphql`
  fragment ContentfulMainHeaderData on ContentfulMain {
    navigationLinks {
      __typename
      ... on ContentfulPost {
        id
      }
      ... on ContentfulCmsPage {
        id
        title
        ...LinkableCmsPageFragment
      }
      ... on ContentfulCta {
        ...CTAFragment
      }
    }

    testRide {
      __typename
      ... on ContentfulCta {
        id
        externalLink
        label
      }
    }

    subscription {
      __typename
      ... on ContentfulCta {
        id
        externalLink
        label
      }
    }

    testRideMobileText

    buyUnu {
      __typename
      ... on ContentfulBuyUnuPage {
        id
        slug
        slugs {
          shipUnu
          payUnu
        }
        title
        shopHeaderTitle

        shipUnuTitle

        payUnuTitle
      }
    }

    localeSuggestionOverlayText {
      raw
    }
  }
`
